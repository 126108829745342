import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { MoreHorizontal } from 'lucide-react';

interface Action {
  label: string;
  onClick: () => void;
  icon?: React.ReactNode;
}

interface ElementWithActionsProps {
  children: React.ReactNode;
  actions: Action[];
  className?: string;
  dataClickBypass?: boolean;
}

export function ElementWithActions({
  children,
  actions,
  className,
  dataClickBypass,
}: ElementWithActionsProps) {
  return (
    <div className={cn('inline-flex items-center', className)}>
      <div className="group flex items-center gap-2">
        <span>{children}</span>
        <div className="flex items-center gap-1 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
          {actions.map((action) => (
            <Button
              key={action.label}
              onClick={action.onClick}
              variant="ghost"
              size="sm"
              className="inline-flex shrink-0 items-center gap-1 p-1"
              title={action.label}
              data-click-bypass={dataClickBypass}
            >
              {action.icon ?? <MoreHorizontal className="h-4 w-4" />}
              <span className="sr-only">{action.label}</span>
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}
