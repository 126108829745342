import { SpecularLink } from '@/components/common/SpecularLink';
import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { cn } from '@/lib/utils';
import { NVDCVEvulnscan } from '@/types/api/response/nvd';
import ExpertChat from '@/v2/components/AIChat/ExpertChat';
import SmartAI from '@/v2/icons/Global/SmartAI';
import NavSpecularLogo from '@/v2/icons/nav-specular-logo.svg?react';
import { getCVSSScoreColor } from '@/v2/utils';
import { useQuery } from '@tanstack/react-query';
import { ArrowLeft } from 'lucide-react';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPassiveScanResultDetailsQuery } from '../../queries/investigation';

function formatTimestamp(timestamp: string) {
  const date = DateTime.fromISO(timestamp);
  return date.toLocaleString({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export default function PassiveDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isChatVisible, setIsChatVisible] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');

  const { data: vulnerability, isLoading } = useQuery<NVDCVEvulnscan>(
    getPassiveScanResultDetailsQuery(id!),
  );

  const handleChatVisibilityChange = (isVisible: boolean) => {
    setIsChatVisible(isVisible);
  };

  const handleCreateIssue = () => {
    if (!vulnerability) return;

    const params = new URLSearchParams({
      resourceType: 'HTTPAsset',
      resourceId: vulnerability.http_asset.id.toString(),
      resourceData: encodeURIComponent(JSON.stringify(vulnerability.http_asset)),
      vulnType: 'NVDVulnerabilities',
      vulnId: vulnerability.vulnerability.id.toString(),
      vulnData: encodeURIComponent(JSON.stringify(vulnerability.vulnerability)),
      description: encodeURIComponent(vulnerability.vulnerability.description),
    });

    navigate(`/issues/new?${params.toString()}`);
  };

  if (isLoading) {
    return (
      <div className="p-4 space-y-4">
        <Skeleton className="h-8 w-64" />
        <Skeleton className="h-48 w-full" />
      </div>
    );
  }

  if (!vulnerability) return null;

  return (
    <div className="bg-background">
      <div className="py-6 px-6 container mx-auto 2xl:px-24">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-4">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => window.history.back()}
              className="text-muted-foreground hover:text-foreground"
            >
              <ArrowLeft className="mr-2 h-4 w-4" />
              Back
            </Button>
          </div>
          <Button onClick={handleCreateIssue} size="sm">
            Create Issue
          </Button>
        </div>

        <div className="relative">
          <ResizablePanelGroup direction="horizontal">
            <ResizablePanel defaultSize={55} minSize={50} maxSize={72}>
              <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
                <TabsList className="grid w-full grid-cols-3 mb-4">
                  <TabsTrigger value="overview">Overview</TabsTrigger>
                  <TabsTrigger value="analysis">
                    <SmartAI
                      className={cn('w-6 h-6 text-v2-orange hover:text-v2-orange-dark mr-1')}
                    />
                    Analysis
                  </TabsTrigger>
                  <TabsTrigger value="remediation">
                    <SmartAI
                      className={cn('w-6 h-6 text-v2-orange hover:text-v2-orange-dark mr-1')}
                    />
                    Remediation
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="overview">
                  <Card className="h-full w-full max-h-[calc(100vh-16rem)] overflow-y-auto min-h-[calc(100vh-32rem)]">
                    <CardHeader>
                      <div className="grid grid-cols-1 md:grid-cols-[1fr,300px] gap-6">
                        <div className="space-y-4">
                          <h1 className="text-xl font-semibold">
                            {vulnerability.vulnerability.cve_id}
                          </h1>
                          <div>
                            <h3 className="text-sm font-medium text-muted-foreground">
                              CVSS Score
                            </h3>
                            <h3
                              className="text-xl font-bold"
                              style={{
                                color: getCVSSScoreColor(
                                  vulnerability.vulnerability.cvss3_base_score ?? '-',
                                ),
                              }}
                            >
                              {vulnerability.vulnerability.cvss3_base_score}
                            </h3>
                          </div>
                          <div>
                            <h3 className="text-sm font-medium mb-2 text-muted-foreground">
                              Description
                            </h3>
                            <div className="rounded-lg border p-4">
                              <h3>{vulnerability.vulnerability.description}</h3>
                            </div>
                          </div>
                        </div>

                        <Card>
                          <CardContent className="pt-6">
                            <div className="space-y-4">
                              <div>
                                <h3 className="text-sm font-medium mb-2 text-muted-foreground">
                                  Asset Information
                                </h3>
                                <div className="space-y-2">
                                  <div>
                                    <span className="font-semibold">Domain:</span>{' '}
                                    {vulnerability.http_asset.hostname.length > 20
                                      ? `${vulnerability.http_asset.hostname.slice(0, 20)}...`
                                      : vulnerability.http_asset.hostname}
                                  </div>
                                  <div>
                                    <span className="font-semibold">Port:</span>{' '}
                                    {vulnerability.http_asset.port}
                                  </div>
                                  <div>
                                    <span className="font-semibold">First Identified:</span>{' '}
                                    {formatTimestamp(vulnerability.date_added)}
                                  </div>
                                  <div>
                                    <span className="font-semibold">Last Seen:</span>{' '}
                                    {formatTimestamp(vulnerability.last_seen)}
                                  </div>
                                  <SpecularLink
                                    resourceType="HTTPAsset"
                                    id={vulnerability.http_asset.id.toString()}
                                    title={'Asset Details'}
                                  >
                                    {vulnerability.http_asset.hostname.length > 20
                                      ? `${vulnerability.http_asset.hostname.slice(0, 20)}...`
                                      : vulnerability.http_asset.hostname}
                                    :{vulnerability.http_asset.port}
                                  </SpecularLink>
                                </div>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                    </CardHeader>
                  </Card>
                </TabsContent>
                <TabsContent value="analysis">
                  <Card className="h-full w-full max-h-[calc(100vh-16rem)] overflow-y-auto min-h-[calc(100vh-32rem)]">
                    <CardHeader>
                      <CardTitle>AI Analysis</CardTitle>
                    </CardHeader>
                    <CardContent>
                      {vulnerability.vulnerability.ai_analysis.description ? (
                        <div className="space-y-4">
                          <Card>
                            <CardContent className="pt-6">
                              <MarkdownViewer markdown={vulnerability.vulnerability.ai_analysis.description} />
                            </CardContent>
                          </Card>
                        </div>
                      ) : (
                        <div className="flex flex-col gap-4 items-center justify-center p-8">
                          <div className="text-gray-500">No AI Analysis available yet</div>
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="remediation">
                  <Card className="h-full w-full max-h-[calc(100vh-16rem)] overflow-y-auto min-h-[calc(100vh-32rem)]">
                    <CardHeader>
                      <CardTitle>AI Remediation</CardTitle>
                    </CardHeader>
                    <CardContent>
                      {vulnerability.vulnerability.ai_remediation ? (
                        <div className="space-y-4">
                          {vulnerability.vulnerability.ai_remediation.brief && (
                            <Card>
                              <CardContent className="pt-6">
                                <h4 className="text-sm font-medium mb-2">Overview</h4>
                                <MarkdownViewer
                                  markdown={vulnerability.vulnerability.ai_remediation.brief}
                                />
                              </CardContent>
                            </Card>
                          )}
                          {vulnerability.vulnerability.ai_remediation.steps &&
                            vulnerability.vulnerability.ai_remediation.steps.length > 0 && (
                              <Card>
                                <CardContent className="pt-6">
                                  <h4 className="text-sm font-medium mb-2">Detailed Steps</h4>
                                  <div className="space-y-2">
                                    {vulnerability.vulnerability.ai_remediation.steps.map(
                                      (step, index) => (
                                        <div key={index}>
                                          <MarkdownViewer markdown={`${index + 1}. ${step}`} />
                                        </div>
                                      ),
                                    )}
                                  </div>
                                </CardContent>
                              </Card>
                            )}
                        </div>
                      ) : (
                        <div className="flex flex-col gap-4 items-center justify-center p-8">
                          <div className="text-gray-500">No Remediation Steps available yet</div>
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </TabsContent>
              </Tabs>
            </ResizablePanel>
            {isChatVisible && (
              <>
                <ResizableHandle />
                <ExpertChat
                  context={[
                    {
                      type: 'vulnerability',
                      id: vulnerability.vulnerability.cve_id,
                    },
                    {
                      type: 'httpasset',
                      id:
                        vulnerability.http_asset.hostname.slice(0, 20) +
                        (vulnerability.http_asset.hostname.length > 20 ? '...' : ''),
                    },
                  ]}
                  chatId={`httpassetvulnerability:${vulnerability.id}`}
                  title={vulnerability.vulnerability.cve_id}
                  onClose={() => handleChatVisibilityChange(false)}
                  onOpen={() => handleChatVisibilityChange(true)}
                />
              </>
            )}
          </ResizablePanelGroup>
          {!isChatVisible && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => handleChatVisibilityChange(true)}
              className="fixed bottom-4 right-4 flex items-center gap-2"
            >
              <NavSpecularLogo className="h-4 w-4" />
              Open Specular AI
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
