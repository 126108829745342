import { z } from 'zod';

export const AffectedResourceIPAddressV4Schema = z.object({
  type: z.literal('ipaddress'),
  id: z.number(),
  ipaddress: z.string(),
  asn: z.null(),
});
export type AffectedResourceIPAddressV4 = z.infer<typeof AffectedResourceIPAddressV4Schema>;

export const AffectedResourceHTTPAssetSchema = z.object({
  type: z.literal('http_asset'),
  id: z.number(),
  hostname: z.string(),
  ipaddressv4: z.null(),
  domain: z.object({
    id: z.number(),
    domainname: z.string(),
    resolves: z.boolean(),
    alternate_dns_entries: z.array(z.string()),
    dns_record: z.object({
      domainname: z.string(),
      cname: z.string(),
      ipaddress: z.array(
        z.object({
          id: z.number(),
          ipaddress: z.string(),
          asn: z.object({
            iprange: z.string(),
            asn: z.number(),
            owner: z.string(),
          }),
        }),
      ),
      last_seen: z.string(),
    }),
    sources: z.record(z.unknown()),
  }),
  port: z.number(),
  dns_record: z.object({
    domainname: z.string(),
    cname: z.string(),
    ipaddress: z.array(
      z.object({
        id: z.number(),
        ipaddress: z.string(),
        asn: z.object({
          iprange: z.string(),
          asn: z.number(),
          owner: z.string(),
        }),
      }),
    ),
    last_seen: z.string(),
  }),
  alternative_dns_resolution: z.null(),
  last_scanned: z.string(),
  created_at: z.string(),
});
export type AffectedResourceHTTPAsset = z.infer<typeof AffectedResourceHTTPAssetSchema>;

export const AffectedResourceDomainSchema = z.object({
  type: z.literal('domain'),
  id: z.number(),
  domainname: z.string(),
  resolves: z.boolean(),
  alternate_dns_entries: z.array(z.string()),
  dns_record: z.null(),
  sources: z.record(z.unknown()),
});
export type AffectedResourceDomain = z.infer<typeof AffectedResourceDomainSchema>;

export const CustomerSchema = z.object({
  customername: z.string(),
  icon_url: z.string(),
});
export type Customer = z.infer<typeof CustomerSchema>;

export const UserSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  username: z.string(),
  email: z.string(),
  is_staff: z.boolean(),
});
export type User = z.infer<typeof UserSchema>;

export const AssignedToSchema = z.object({
  id: z.number(),
  user: UserSchema,
  customer: CustomerSchema,
});
export type AssignedTo = z.infer<typeof AssignedToSchema>;

export const IssueVulnerabilitySchema = z.object({
  id: z.number(),
  cve_id: z.string(),
  cvss3_base_score: z.number().nullable(),
  cvss3_exploitability_score: z.number().nullable(),
  description: z.string(),
  cpe_uris: z.record(z.unknown()),
  cisa_kev: z.boolean(),
});
export type IssueVulnerability = z.infer<typeof IssueVulnerabilitySchema>;

export const IssueSchema = z.object({
  id: z.number().readonly(),
  issue_number: z.number().readonly(),
  customer: z.string().readonly(),
  severity: z.enum(['high', 'low', 'medium', 'critical', 'informational']),
  title: z.string().min(1).max(255),
  description: z.string().min(1),
  status: z.enum([
    'draft',
    'new',
    'acknowledged',
    'in_progress',
    'mitigated',
    'resolved',
    'ignored',
  ]),
  is_resolved: z.boolean(),
  affected_resource_model_type: z.string().min(1).nullable(),
  affected_resource_object_id: z.number().nullable(),
  affected_resource: z
    .discriminatedUnion('type', [
      AffectedResourceIPAddressV4Schema,
      AffectedResourceHTTPAssetSchema,
      AffectedResourceDomainSchema,
    ])
    .nullable(),
  vuln_model_type: z.string().min(1).nullable(),
  vuln_object_id: z.number().nullable(),
  vulnerability: IssueVulnerabilitySchema.nullable(),
  created_by: AssignedToSchema,
  assigned_to_id: z.number().nullable(),
  assigned_to: AssignedToSchema.nullable(),
  resolved_at: z.string().datetime().nullable().readonly(),
  updated_at: z.string().datetime().readonly(),
  created_at: z.string().datetime().readonly(),
});

export const IssuesResponseSchema = z.object({
  entries: z.array(IssueSchema),
  page_number: z.string(),
  total_pages: z.number(),
  total_count: z.number(),
});

export type Issue = z.infer<typeof IssueSchema>;
export type IssuesResponse = z.infer<typeof IssuesResponseSchema>;
export const ISSUE_STATUSES = {
  draft: 'bg-gray-400 hover:bg-gray-300 text-gray-950',
  new: 'bg-red-400 hover:bg-red-300 text-red-950',
  acknowledged: 'bg-yellow-300 hover:bg-amber-300 text-amber-950',
  in_progress: 'bg-yellow-500 hover:bg-yellow-400 text-yellow-950',
  mitigated: 'bg-blue-300 hover:bg-blue-300 text-blue-950',
  resolved: 'bg-green-500 hover:bg-green-400 text-green-950',
  ignored: 'bg-gray-300 hover:bg-gray-300 text-gray-950',
} as const;

export const ISSUE_STATUSES_NAMES = {
  draft: 'Draft',
  new: 'New',
  acknowledged: 'Acknowledged',
  in_progress: 'In Progress',
  mitigated: 'Mitigated',
  resolved: 'Resolved',
  ignored: 'Ignored',
} as const;
