import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Form, FormControl, FormDescription, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useAuth } from '@/Helpers/AuthContext';
import AuthBackground from '@/v2/components/AuthBackground/AuthBackground';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertCircle, Loader2 } from 'lucide-react';
import { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Please enter a valid email address' })
    .trim()
    .toLowerCase(),
  password: z.string().min(1, { message: 'Password is required' }),
});

const Login = () => {
  const [error, setError] = useState<ReactNode>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { login, isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  const isVerification = searchParams.get('verification') === 'true';

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const { isValid, isDirty } = form.formState;

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/issues');
    }
  }, [isAuthenticated, navigate]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    if (isSubmitting || !isValid) return;
    setError('');
    setIsSubmitting(true);

    try {
      const result = formSchema.safeParse(data);
      if (!result.success) {
        const formattedErrors = result.error.issues.map((issue) => issue.message).join('. ');
        setError(formattedErrors);
        return;
      }

      const { email, password } = result.data;
      const response = await login(email, password);

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.error) {
          const errorMessages = Object.entries(errorData.error)
            .map(([field, message]) => {
              if (field === '0') return message as string;
              return `${field}: ${Array.isArray(message) ? message[0] : message}`;
            })
            .filter(Boolean)
            .join('. ');
          setError(errorMessages || 'Invalid credentials. Please try again.');
          form.setValue('password', '');
          form.setFocus('password');
        } else {
          setError('Invalid credentials. Please try again.');
        }
      } else {
        navigate('/issues');
      }
    } catch (error: any) {
      if (error.status === 403) {
        setError(error?.data?.error?.detail || 'Something went wrong. Please try again later.');
      } else {
        setError('Something went wrong. Please try again later.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative flex justify-center items-center max-h-screen bg-v2-black overflow-hidden gap-16">
      <div className="relative flex justify-between items-center min-h-screen bg-v2-black overflow-hidden min-w-[500px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full max-w-[400px] space-y-6">
            <h1 className="text-v2-white text-2xl mb-8 uppercase tracking-wider text-center font-lato font-bold">
              Log in
            </h1>

            {isVerification && (
              <Alert className="mb-6 bg-yellow-500/10 border border-yellow-500/20">
                <AlertCircle className="h-4 w-4 text-yellow-500" />
                <AlertTitle className="text-v2-white font-medium">
                  Email verification required
                </AlertTitle>
                <AlertDescription className="text-v2-white/80">
                  Please check your email for the verification link before logging in.
                </AlertDescription>
              </Alert>
            )}

            {error && (
              <div className="p-3 rounded-md bg-red-500/10 border border-red-500 text-red-500 animate-in fade-in duration-200">
                {error}
              </div>
            )}

            <div className="space-y-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="email"
                        required
                        placeholder="Email"
                        autoComplete="email"
                        disabled={isSubmitting}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500 animate-in fade-in duration-200">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Enter your email</FormDescription>
                    )}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="password"
                        required
                        placeholder="Password"
                        autoComplete="current-password"
                        disabled={isSubmitting}
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500 animate-in fade-in duration-200">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Enter your password</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>

            <div className="flex justify-end">
              <Link
                to="/forgot-password"
                className="text-v2-white/50 text-sm hover:text-v2-orange transition-colors"
                tabIndex={isSubmitting ? -1 : 0}
              >
                Forgot Password?
              </Link>
            </div>

            <button
              type="submit"
              disabled={isSubmitting || !isValid || !isDirty}
              className={`w-full py-3 px-3 text-v2-white text-base rounded-md
                cursor-pointer transition-colors font-poppins font-semibold disabled:cursor-not-allowed flex items-center justify-center bg-v2-orange hover:bg-v2-orange/90`}
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Logging in...
                </>
              ) : (
                'Log in'
              )}
            </button>
          </form>
        </Form>
      </div>
      <AuthBackground />
    </div>
  );
};

export default Login;
