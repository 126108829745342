import { useAuth } from '@/Helpers/AuthContext';
import { cn } from '@/lib/utils';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AuthDropdown from '../AuthDropdown/AuthDropdown';
interface LayoutHeaderProps {
  links: {
    label: string;
    to: string;
    prefetch?: () => void;
  }[];
  actions?: {
    component: React.ReactNode;
  }[];
}

const TabbedNavLink = ({
  lone,
  label,
  to,
  prefetch,
}: {
  lone: boolean;
  label: string;
  to: string;
  prefetch?: () => void;
}) => {
  const location = useLocation();
  const isActive =
    to === '/assets'
      ? location.pathname === '/assets' || location.pathname.match(/^\/assets\/\d+/)
      : to === '/investigation/passive'
        ? location.pathname === '/investigation/passive' ||
          location.pathname.match(/^\/investigation\/passive\/\d+/)
        : to === '/investigation/dynamic'
          ? location.pathname === '/investigation/dynamic' ||
            location.pathname.match(/^\/investigation\/dynamic\/\d+/)
          : to === '/investigation/ports'
            ? location.pathname === '/investigation/ports'
            : location.pathname === to;

  return (
    <NavLink to={to} title={label} onMouseEnter={prefetch}>
      <span
        className={cn(
          'flex items-center gap-2 px-4 py-2 rounded-lg text-gray-500 text-xl font-semibold transition-all duration-200',
          !lone && 'hover:text-gray-700 hover:bg-gray-100',
          (lone || isActive) && 'text-black bg-gray-100 shadow-sm',
        )}
      >
        <span>{label}</span>
      </span>
    </NavLink>
  );
};

const LayoutHeader = ({ links, actions }: LayoutHeaderProps) => {
  const { userData, userOrganization, logout, isStaff, staffOrganizations } = useAuth();
  const navigate = useNavigate();

  const handleLogoff = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <header className="header sticky top-0 z-10 flex flex-row py-4 px-4  border-b bg-v2-white border-b-v2-warm-gray">
      <div className="flex flex-1 items-center gap-4">
        {links.map((link) => (
          <TabbedNavLink lone={links.length === 1} key={link.to} label={link.label} to={link.to} />
        ))}
        {actions && (
          <div className="flex flex-1 items-center gap-4">
            {actions.map((action) => action.component)}
          </div>
        )}
      </div>

      <AuthDropdown
        userData={userData}
        userOrganization={userOrganization}
        isStaff={isStaff}
        staffOrganizations={staffOrganizations}
        handleLogoff={handleLogoff}
      />
    </header>
  );
};

export default LayoutHeader;
