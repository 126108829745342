import AssetSources from '@/GridComponents/AssetSources';
import { DomainResponse } from '@/types/api/response/domains';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { ElementWithActions } from '@/v2/components/ElementWithActions/ElementWithActions';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import { getDomainsQuery } from '@/v2/queries/domains';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Filter, Network } from 'lucide-react';
import { parseAsInteger, parseAsString, useQueryState } from 'nuqs';

const Domains = () => {
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [domainSearchValue, setDomainSearchValue] = useQueryState(
    'domain_search_value',
    parseAsString.withDefault(''),
  );
  const [domainSearchType, setDomainSearchType] = useQueryState(
    'domain_search_type',
    parseAsString.withDefault('startswith'),
  );

  const searchParamsState = domainSearchValue
    ? { domainname: domainSearchValue, filter_type: domainSearchType, page }
    : { page };

  const { data, isLoading, error } = useQuery(getDomainsQuery(searchParamsState));

  const handleSelectDomains = (value: string | string[]) => {
    setDomainSearchValue(value as string);
    setPage(1);
  };

  const handleSelectDomainType = (value: string) => {
    setDomainSearchType(value);
    setPage(1);
  };

  const columns: ColumnDef<DomainResponse['entries'][0]>[] = [
    {
      header: 'Domain',
      accessorKey: 'domainname',
      cell: ({ row }) => {
        return (
          <ElementWithActions
            actions={[
              {
                label: 'Set Domain Filter',
                onClick: () => handleSelectDomains(row.original.domainname),
                icon: <Filter className="h-4 w-4" />,
              },
            ]}
          >
            {row.original.domainname}
          </ElementWithActions>
        );
      },
    },
    {
      header: 'DNS Record',
      accessorKey: 'dns_record',
      cell: ({ row }) => {
        const dnsRecord = row.original.dns_record;
        if (!dnsRecord) return <div>No DNS Record</div>;

        return (
          <div className="flex flex-col gap-2 items-start">
            <div>CNAME: {dnsRecord.cname || 'N/A'}</div>
            <div className="flex flex-col gap-2">
              {dnsRecord.ipaddress.map((ip) => (
                <div key={ip.id}>
                  <LinkWithAction
                    showAsButton={true}
                    buttonIcon={<Network className="h-4 w-4" />}
                    to={`/ipaddress/${ip.ipaddress}?from=domains`}
                    title="View IP Address"
                    dataClickBypass={true}
                    actions={[]}
                  >
                    IP: {ip.ipaddress}
                  </LinkWithAction>
                  {ip.asn && (
                    <div>
                      ASN: {ip.asn.asn} ({ip.asn.owner})
                      <br />
                      Range: {ip.asn.iprange}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      header: 'Sources',
      accessorKey: 'sources',
      cell: ({ row }) => {
        if (!row.original.sources || Object.keys(row.original.sources).length === 0)
          return <div>No Sources</div>;
        return <AssetSources entry={row.original} />;
      },
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const filters: AvailableFilter[] = [
    {
      label: 'Domain',
      state: domainSearchValue,
      setState: handleSelectDomains,
      setValueType: handleSelectDomainType,
      valueType: domainSearchType,
      type: 'domainSearch',
      key: 'domain_search_value',
      placeholder: 'Search by domain',
      onClear: () => {
        setDomainSearchValue('');
        setDomainSearchType('startswith');
      },
      options: [
        {
          label: 'Starts with',
          value: 'startswith',
        },
        {
          label: 'Contains',
          value: 'contains',
        },
      ],
    },
  ];

  const handleClearAllFilters = () => {
    setDomainSearchValue('');
    setDomainSearchType('startswith');
  };

  return (
    <div className="p-4 max-w-[2000px] mx-auto">
      <div className="flex flex-row gap-4 w-full">
        <UniversalFilter
          filters={filters}
          clearAllFilters={handleClearAllFilters}
          className="mb-4"
        />
      </div>
      <DataTable
        columns={columns}
        data={data?.entries || []}
        currentPage={page}
        totalPages={data?.total_pages}
        totalEntries={data?.total_count}
        onPageChange={handlePageChange}
        loading={isLoading}
        error={error}
        tableHeight="calc(100vh - 220px)"
      />
    </div>
  );
};

export default Domains;
