import { createIssue, NewIssuePayload } from '@/api/issues.api';
import MarkdownEditor from '@/components/Markdown/MarkdownEditor';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useToast } from '@/hooks/use-toast';
import { ImageMap, useAttachedImages } from '@/hooks/useAttachedImages';
import { ResourceSelector } from '@/pages/Issues/ResourceSelector';
import { VulnerabilitySelector } from '@/pages/Issues/VulnerabilitySelector';
import { NVDVulnerability } from '@/types/api/response/nvd';
import { useUpdateIssuesCache } from '@/v2/queries/issues';
import { getUsersQuery } from '@/v2/queries/users';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { ArrowLeft, Edit } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useBeforeUnload, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const newIssueSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description is required'),
  severity: z.enum(['informational', 'low', 'medium', 'high', 'critical']),
  affected_resource_model_type: z.string().nullable(),
  affected_resource_object_id: z.number().nullable(),
  vuln_model_type: z.string().nullable(),
  vuln_object_id: z.number().nullable(),
  assignedToId: z.string().optional(),
  status: z.enum([
    'draft',
    'new',
    'acknowledged',
    'in_progress',
    'mitigated',
    'resolved',
    'ignored',
  ]),
  _uploaded_screenshots: z.record(
    z.string(),
    z
      .object({
        filename: z.string().min(1),
        blob: z.instanceof(Blob),
      })
      .strict(),
  ) as z.ZodType<ImageMap>,
});

export default function NewIssuePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();
  const { data: users } = useQuery(getUsersQuery());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedResource, setSelectedResource] = useState<any>(null);
  const [selectedResourceType, setSelectedResourceType] = useState<string | null>(null);
  const [selectedVulnerabilityType, setSelectedVulnerabilityType] = useState<string | null>(null);
  const [selectedVulnerability, setSelectedVulnerability] = useState<NVDVulnerability | null>(null);
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const [intendedPath, setIntendedPath] = useState<string | null>(null);

  // Get initial values from URL params
  const initialDescription = searchParams.get('description')
    ? decodeURIComponent(searchParams.get('description')!)
    : '';

  const form = useForm<NewIssuePayload>({
    resolver: zodResolver(newIssueSchema),
    defaultValues: {
      title: '',
      description: initialDescription,
      severity: 'informational',
      affected_resource_model_type: null,
      affected_resource_object_id: null,
      vuln_model_type: null,
      vuln_object_id: null,
      assignedToId: '',
      status: 'new',
      _uploaded_screenshots: {} as ImageMap,
    },
  });

  // Handle pre-populated data from URL
  useEffect(() => {
    const resourceType = searchParams.get('resourceType');
    const resourceId = searchParams.get('resourceId');
    const resourceData = searchParams.get('resourceData');
    const vulnType = searchParams.get('vulnType');
    const vulnId = searchParams.get('vulnId');
    const vulnData = searchParams.get('vulnData');

    // Pre-populate resource if provided
    if (resourceType && resourceId && resourceData) {
      const resource = JSON.parse(decodeURIComponent(resourceData));
      handleResourceSelect(resourceType, resource);
    }

    // Pre-populate vulnerability if provided
    if (vulnType && vulnId && vulnData) {
      const vulnerability = JSON.parse(decodeURIComponent(vulnData));
      handleVulnerabilitySelect(vulnType, vulnerability);
    }
  }, [searchParams]);

  const handleResourceSelect = (resourceType: string | null, resource: any) => {
    if (resourceType && !resource) {
      setSelectedResourceType(resourceType);
      form.setValue('affected_resource_model_type', resourceType);
      return;
    }

    setSelectedResourceType(resourceType);
    setSelectedResource(resource);
    form.setValue('affected_resource_model_type', resourceType);
    form.setValue('affected_resource_object_id', resource?.id);
  };

  const handleVulnerabilitySelect = (
    vulnerabilityType: string | null,
    vulnerability: NVDVulnerability | null,
  ) => {
    setSelectedVulnerabilityType(vulnerabilityType);
    form.setValue('vuln_model_type', vulnerabilityType);

    if (vulnerability) {
      setSelectedVulnerability(vulnerability);
      form.setValue('vuln_object_id', vulnerability.id);
    }
  };

  const handleResourceClear = () => {
    setSelectedResource(null);
    setSelectedResourceType(null);
    form.setValue('affected_resource_model_type', null);
    form.setValue('affected_resource_object_id', null);
  };

  const { addImage } = useAttachedImages((imageMap) => {
    form.setValue('_uploaded_screenshots', imageMap);
  });

  const handleImageUpload = async (file: File) => {
    const url = await addImage(file);
    return url;
  };

  // Add beforeunload event listener
  useBeforeUnload(
    useCallback(
      (event) => {
        if (form.formState.isDirty) {
          event.preventDefault();
          return '';
        }
      },
      [form.formState.isDirty],
    ),
  );

  // Handle navigation attempts
  useEffect(() => {
    const handleBeforeNavigate = (event: BeforeUnloadEvent) => {
      if (form.formState.isDirty) {
        event.preventDefault();
        setShowExitPrompt(true);
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeNavigate);
    return () => window.removeEventListener('beforeunload', handleBeforeNavigate);
  }, [form.formState.isDirty]);

  const handleNavigationAttempt = (path: string) => {
    if (form.formState.isDirty) {
      setIntendedPath(path);
      setShowExitPrompt(true);
    } else {
      navigate(path);
    }
  };

  const updateIssuesCache = useUpdateIssuesCache();

  const handleSaveAsDraft = async () => {
    try {
      setIsSubmitting(true);
      const data = form.getValues();

      // Generate default title if empty
      const currentDate = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });

      const defaultTitle = `Draft - ${currentDate}`;

      const createdIssue = await createIssue({
        ...data,
        title: data.title || defaultTitle,
        severity: data.severity || 'informational',
        description: data.description || 'Draft issue',
        status: 'draft',
      });

      // Update cache with new issue
      updateIssuesCache(createdIssue);

      toast({
        title: 'Draft saved',
        description: (
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <span className="text-sm text-muted-foreground">SPE-{createdIssue.issue_number}</span>
              <span>—</span>
              <span className="text-sm text-muted-foreground">{createdIssue.title}</span>
            </div>
            <Button
              variant="link"
              className="p-0 h-auto font-normal text-left text-blue-500 hover:text-blue-600 hover:no-underline"
              onClick={() => navigate(`/issues/${createdIssue.issue_number}`)}
            >
              View issue
            </Button>
          </div>
        ),
        duration: 5000,
      });
      if (intendedPath) {
        navigate(intendedPath);
      } else {
        navigate('/issues');
      }
    } catch (error) {
      console.error('Failed to save draft:', error);
      toast({
        title: 'Error',
        description: 'Failed to save draft. Please try again.',
        variant: 'destructive',
        duration: 5000,
      });
    } finally {
      setIsSubmitting(false);
      setShowExitPrompt(false);
    }
  };

  const handleSubmit = async (data: NewIssuePayload, isDraft: boolean = false) => {
    try {
      setIsSubmitting(true);
      const createdIssue = await createIssue({
        ...data,
        status: isDraft ? 'draft' : 'new',
      });

      // Update cache with new issue
      updateIssuesCache(createdIssue);

      toast({
        title: isDraft ? 'Draft saved' : 'Issue created',
        description: (
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <span className="text-sm text-muted-foreground">{createdIssue.issue_number}</span>
              <span>—</span>
              <span className="text-sm text-muted-foreground">{createdIssue.title}</span>
            </div>
            <Button
              variant="link"
              className="p-0 h-auto font-normal text-left text-blue-500 hover:text-blue-600 hover:no-underline"
              onClick={() => navigate(`/issues/${createdIssue.issue_number}`)}
            >
              View issue
            </Button>
          </div>
        ),
        duration: 5000,
      });
      navigate('/issues');
    } catch (error) {
      console.error('Failed to create issue:', error);
      toast({
        title: 'Error',
        description: 'Failed to create issue. Please try again.',
        variant: 'destructive',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="px-6 py-4 max-w-[1600px] mx-auto">
      <div className="flex flex-col gap-2 mb-4 justify-start items-start">
        <Button variant="ghost" onClick={() => handleNavigationAttempt('/issues')} className="px-0">
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Issues
        </Button>
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-bold">New Issue</h1>
        </div>
      </div>

      <div className="bg-background rounded-lg border p-6">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) => handleSubmit(data, false))}
            className="space-y-8"
          >
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-foreground flex items-center group">
                    <strong>Title</strong> <span className="text-red-500">*</span>
                    <Edit className="ml-2 text-gray-500 group-hover:visible invisible" />
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Enter a clear, concise title"
                      className="text-foreground"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <div className="flex justify-start gap-4">
              <FormField
                control={form.control}
                name="affected_resource_model_type"
                render={() => (
                  <FormItem>
                    <FormLabel className="text-foreground">
                      <strong>Affected Resource</strong>
                    </FormLabel>
                    <ResourceSelector
                      placeholderText="Select type (optional)"
                      selectedResourceType={selectedResourceType}
                      selectedResource={selectedResource}
                      onResourceSelect={handleResourceSelect}
                      onClear={handleResourceClear}
                    />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="vuln_model_type"
                render={() => (
                  <FormItem>
                    <FormLabel className="text-foreground">
                      <strong>Vulnerability</strong>
                    </FormLabel>
                    <VulnerabilitySelector
                      placeholderText="Select type (optional)"
                      selectedVulnerabilityType={selectedVulnerabilityType}
                      selectedVulnerability={selectedVulnerability}
                      onVulnerabilitySelect={handleVulnerabilitySelect}
                      target="_blank"
                      onClear={() => {
                        setSelectedVulnerability(null);
                        setSelectedVulnerabilityType(null);
                        form.setValue('vuln_model_type', null);
                        form.setValue('vuln_object_id', null);
                      }}
                    />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="severity"
                render={({ field }) => (
                  <FormItem className="mr-4">
                    <FormLabel className="text-foreground">
                      <strong>Severity</strong> <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger className="border rounded-md bg-background text-foreground">
                          <SelectValue placeholder="Select severity" className="text-foreground" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="informational">Informational</SelectItem>
                        <SelectItem value="low">Low</SelectItem>
                        <SelectItem value="medium">Medium</SelectItem>
                        <SelectItem value="high">High</SelectItem>
                        <SelectItem value="critical">Critical</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="assignedToId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-foreground">
                      <strong>Assigned To</strong>
                    </FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger className="border rounded-md bg-background text-foreground">
                          <SelectValue
                            placeholder="Select a user (optional)"
                            className="text-foreground"
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {(users?.entries ?? []).map((user) => (
                          <SelectItem
                            key={user.id}
                            value={user.id.toString()}
                            className="text-foreground"
                          >
                            {user.user.first_name} {user.user.last_name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="border rounded-lg p-4">
                  <FormLabel className="text-foreground flex items-center group">
                    <strong>Description</strong> <span className="text-red-500">*</span>
                    <Edit className="ml-2 text-gray-500 group-hover:visible invisible" />
                  </FormLabel>
                  <FormControl>
                    <MarkdownEditor
                      markdown={field.value}
                      placeholder="Provide detailed information about the issue"
                      onChange={field.onChange}
                      imageUploadHandler={handleImageUpload}
                      embedded
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4">
              <Button
                type="button"
                variant="outline"
                disabled={isSubmitting}
                onClick={() => form.handleSubmit((data) => handleSubmit(data, true))()}
              >
                {isSubmitting ? (
                  <div className="flex items-center gap-2">
                    <div className="h-4 w-4 animate-spin rounded-full border-2 border-gray-300 border-t-gray-600" />
                    Saving...
                  </div>
                ) : (
                  'Create as Draft'
                )}
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <div className="flex items-center gap-2">
                    <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary-foreground border-t-primary-foreground" />
                    Creating...
                  </div>
                ) : (
                  'Create Issue'
                )}
              </Button>
            </div>
          </form>
        </Form>
      </div>

      <AlertDialog open={showExitPrompt} onOpenChange={setShowExitPrompt}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
            <AlertDialogDescription>
              You have unsaved changes. Would you like to save them as a draft before leaving?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel
              onClick={() => {
                setShowExitPrompt(false);
                if (intendedPath) {
                  navigate(intendedPath);
                }
              }}
            >
              Discard
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleSaveAsDraft}
              disabled={isSubmitting}
              className="bg-v2-orange text-white"
            >
              {isSubmitting ? (
                <div className="flex items-center gap-2">
                  <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary-foreground border-t-primary-foreground" />
                  Saving...
                </div>
              ) : (
                'Save as Draft'
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
