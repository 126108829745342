import { changePasswordApi, resetPasswordApi } from '@/api/auth.api';
import { Form, FormControl, FormDescription, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useToast } from '@/hooks/use-toast';
import AuthBackground from '@/v2/components/AuthBackground/AuthBackground';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const formSchema = z
  .object({
    password: z.string().min(8, { message: 'Password must be at least 8 characters' }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });

export default ResetPassword;

function ResetPassword() {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const [resetKey, setResetKey] = useState<string | null>(null);
  const baseApiUrl = import.meta.env.VITE_REACT_APP_API_URL;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    const checkResetKey = async () => {
      try {
        const value = searchParams.get('token');
        setResetKey(value);

        if (!value) {
          toast({
            title: 'No password reset key provided.',
            variant: 'destructive',
          });
          navigate('/login');
          return;
        }

        const response = await resetPasswordApi(value);

        if (response.status !== 200) {
          toast({
            title: response.data?.error || 'Failed to reset password.',
            variant: 'destructive',
          });
          navigate('/login');
        } else {
          toast({
            title: 'Please enter your new password.',
          });
        }
      } catch (error) {
        console.warn('Error checking password reset key', error);
        toast({
          title: 'Unexpected error validating password reset key.',
          variant: 'destructive',
        });
        navigate('/login');
      }
    };

    checkResetKey();
  }, [navigate, searchParams, toast, baseApiUrl]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    try {
      const response = await changePasswordApi(data.password, data.confirmPassword, resetKey);

      if (response.status === 200) {
        toast({
          title: response.data?.message || 'Password changed successfully',
          variant: 'success',
        });

        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } else {
        const errorMessage = response.data?.error || 'Failed to reset password';
        setError(errorMessage);
        toast({
          title: errorMessage,
          variant: 'destructive',
        });
      }
    } catch (error) {
      console.warn('Failed to reset password', error);
      setError('Failed to reset password. Please try again.');
      toast({
        title: 'There was an unexpected error changing the password.',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="relative flex justify-center items-center max-h-screen bg-v2-black overflow-hidden gap-16">
      <div className="relative flex justify-between items-center min-h-screen bg-v2-black overflow-hidden min-w-[500px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full max-w-[400px]">
            <h1 className="text-v2-white text-2xl mb-8 uppercase tracking-wider text-center font-lato font-bold">
              Set New Password
            </h1>

            {error && (
              <div className="mb-4 p-3 rounded-md bg-red-500/10 border border-red-500 text-red-500">
                {error}
              </div>
            )}

            <div className="mb-6">
              <FormField
                control={form.control}
                name="password"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="password"
                        required
                        placeholder="New Password"
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Enter your new password</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="password"
                        required
                        placeholder="Confirm Password"
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Confirm your new password</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>

            <button
              type="submit"
              className="w-full py-3 px-3 bg-v2-orange text-v2-white text-base rounded-md
                   cursor-pointer transition-colors hover:bg-v2-orange/90 font-poppins font-semibold"
            >
              Change Password
            </button>

            <p className="text-v2-white/50 text-center mt-6 text-sm">
              <Link to="/login" className="text-v2-orange no-underline hover:underline">
                Back to Login
              </Link>
            </p>
          </form>
        </Form>
      </div>
      <AuthBackground />
    </div>
  );
}
