import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { useEmailVerification } from '@/hooks/useEmailVerification';
import { useEffect, useRef } from 'react';

export default function EmailVerification() {
  const { verifyEmail } = useEmailVerification();
  const verificationAttempted = useRef(false);

  useEffect(() => {
    if (!verificationAttempted.current) {
      verificationAttempted.current = true;
      verifyEmail();
    }
  }, [verifyEmail]);

  return (
    <div className="container flex items-center justify-center min-h-screen py-12">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>Email Verification</CardTitle>
          <CardDescription>Verifying your email address...</CardDescription>
        </CardHeader>
        <CardContent className="flex justify-center">
          <div className="w-8 h-8 border-4 border-primary border-t-transparent rounded-full animate-spin" />
        </CardContent>
      </Card>
    </div>
  );
}
