import { DateRange } from 'react-day-picker';
import CVESearch from '../CVESearch/CVESearch';
import DateInputComponent from '../DateInputComponent/DateInputComponent';
import DateWithOperator from '../FilterComponents/DateWithOperator';
import { IssuesAffectedResourcesFilter } from '../FilterComponents/IssuesAffectedResourcesFilter';
import NormalSelectFilter from '../FilterComponents/NormalSelectFilter';
import NormalTextFilter from '../FilterComponents/NormalTextFilter';
import GenericInputWithSelect from '../GenericInputWithSelect/GenericInputWithSelect';
import GenericSelectWithOperator from '../GenericSelectWithOperator/GenericSelectWithOperator';
import SeveritySearch from '../SeveritySearch/SeveritySearch';
import TextWithDateInput from '../TextWithDateInput/TextWithDateInput';
import { AvailableFilter } from './UniversalFilter';
import { FilterMultiSelect } from '@/components/ui/filter-multi-select';

interface FilterRendererProps {
  filter: AvailableFilter;
  onUpdate: (filter: AvailableFilter, value: string | string[] | DateRange | Date) => void;
  onClear: (filter: AvailableFilter) => void;
  value: string;
}

export function FilterRenderer({ filter, onUpdate, onClear }: FilterRendererProps) {
  const renderFilter = () => {
    switch (filter.type) {
      case 'select':
        return <NormalSelectFilter filter={filter} onUpdate={onUpdate} />;

      case 'multiSelect':
        return (
          <FilterMultiSelect
            options={filter.options || []}
            value={filter.state as string[]}
            onValueChange={(value) => onUpdate(filter, value)}
            placeholder={filter.placeholder}
          />
        );

      case 'domainSearch':
        return (
          <GenericInputWithSelect
            inputValue={filter.state as string}
            setInputValue={(value) => onUpdate(filter, value)}
            inputType={filter.valueType}
            setInputType={(value) => filter.setValueType?.(value)}
            onClear={() => onClear(filter)}
            placeholder={filter.placeholder}
            options={filter.options || []}
            label={filter.label}
            defaultValue={filter.defaultValue}
          />
        );

      case 'cveSearch':
        return (
          <CVESearch
            inputValue={filter.state as string}
            setInputValue={(value) => onUpdate(filter, value)}
            inputType={filter.valueType}
            setInputType={(value) => filter.setValueType?.(value)}
            onClear={() => onClear(filter)}
          />
        );

      case 'severitySearch':
        return (
          <SeveritySearch
            inputValue={filter.state as string}
            setInputValue={(value) => onUpdate(filter, value)}
            inputType={filter.valueType}
            setInputType={(value) => filter.setValueType?.(value)}
            onClear={() => onClear(filter)}
          />
        );

      case 'text-with-operator':
        return (
          <GenericInputWithSelect
            inputValue={filter.state as string}
            setInputValue={(value) => onUpdate(filter, value)}
            inputType={filter.valueType}
            setInputType={(value) => filter.setValueType?.(value)}
            onClear={() => onClear(filter)}
            placeholder={filter.placeholder}
            options={filter.options || []}
            label={filter.label}
            defaultValue={filter.defaultValue}
          />
        );
      case 'text':
        return <NormalTextFilter filter={filter} onUpdate={onUpdate} />;
      case 'text-with-date':
        return (
          <TextWithDateInput
            ipFilter={filter.state as string}
            fromDate={filter.fromDate as string}
            toDate={filter.toDate as string}
            quickSelect={filter.quickSelect as string}
            handleIpFilterChange={(value) => onUpdate(filter, value)}
            handleDateRangeChange={(value) => onUpdate(filter, value)}
            handleQuickSelectChange={(value) => onUpdate(filter, value)}
          />
        );

      case 'date-input':
        return (
          <DateInputComponent
            fromDate={filter.fromDate as string}
            toDate={filter.toDate as string}
            quickSelect={filter.state as string}
            setDateRange={filter.setDateRange}
            setQuickSelect={(value) => onUpdate(filter, value)}
          />
        );

      case 'select-with-operator':
        return (
          <GenericSelectWithOperator
            options={filter.options || []}
            valueType={filter.state as string}
            setValueType={filter.setState}
            onClear={() => onClear(filter)}
            operator={filter.valueType as string}
            setOperator={filter.setValueType}
            operatorOptions={filter.operatorOptions || []}
            label={filter.label}
            placeholder={filter.placeholder}
          />
        );

      case 'issues-affected-resources':
        return (
          <IssuesAffectedResourcesFilter
            affectedResource={filter.state as string}
            setAffectedResource={(value) => onUpdate(filter, value)}
            affectedResourceOperator={filter.valueType as string}
            setAffectedResourceOperator={filter.setValueType}
          />
        );

      case 'date-with-operator':
        return (
          <DateWithOperator
            fromDate={filter.fromDate as string}
            setFromDate={(value) => onUpdate(filter, value)}
            operator={filter.valueType as string}
            setOperator={filter.setValueType}
            operatorOptions={filter.operatorOptions || []}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="w-full p-2">
      <div className="flex items-center gap-1 w-full">{renderFilter()}</div>
    </div>
  );
}
