import { cn } from '@/lib/utils';
import { User } from '@/types/api/response/user';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { switchOrganizationApi } from '@/api/auth.api';

interface AuthDropdownProps {
  userData: User;
  userOrganization: string;
  handleLogoff: () => void;
  isStaff?: boolean;
  staffOrganizations?: Array<{ customername: string }>;
}

const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const AuthDropdown = ({
  userData,
  handleLogoff,
  isStaff,
  staffOrganizations,
}: AuthDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const CurrentChevronIcon = useMemo(
    () => (isDropdownOpen ? ChevronUp : ChevronDown),
    [isDropdownOpen],
  );

  const handleOrganizationSwitch = async (org: string) => {
    try {
      setIsSwitching(true);
      await switchOrganizationApi(org);
      setIsDropdownOpen(false);
      window.location.reload();
    } catch (error) {
      console.error('Failed to switch organization:', error);
    } finally {
      setIsSwitching(false);
    }
  };

  return (
    <div className="flex items-center relative">
      <button
        className={cn(
          'flex items-center gap-2 text-v2-gray hover:text-v2-gray-hover',
          isDropdownOpen && 'text-v2-gray-hover',
        )}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        data-testid="auth-dropdown-button"
      >
        <span>
          {userData?.first_name && userData?.last_name
            ? `${capitalize(userData.first_name)} ${capitalize(userData.last_name)}`
            : 'User'}
        </span>
        <CurrentChevronIcon className="flex-none w-4 h-4" />
      </button>

      <div
        className={cn(
          'absolute top-full right-0 mt-2 w-max max-w-96 bg-white rounded-md shadow-lg py-1 z-50',
          !isDropdownOpen && 'hidden',
        )}
      >
        {isStaff && staffOrganizations.length > 0 && (
          <div className="border-b px-4 py-2">
            <p className="text-sm text-gray-500 mb-1">Current organization</p>
            <Select
              defaultValue={userData.organization.customername}
              onValueChange={handleOrganizationSwitch}
              disabled={isSwitching}
            >
              <SelectTrigger className="w-[200px] relative z-50">
                <SelectValue placeholder="Change organization" />
              </SelectTrigger>
              <SelectContent position="popper" className="z-[100]" sideOffset={0}>
                {staffOrganizations.map((org, index) => (
                  <SelectItem key={index} value={org.customername}>
                    {org.customername}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
        {!isStaff && (
          <div className="px-4 py-2 text-sm text-gray-700 border-b">
            Organization: {userData.organization.customername}
          </div>
        )}
        <NavLink
          to="/change-password"
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          Change Password
        </NavLink>
        <NavLink
          to="/settings"
          onClick={() => setIsDropdownOpen(false)}
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          Settings
        </NavLink>

        <button
          onClick={handleLogoff}
          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          Log Off
        </button>
      </div>
    </div>
  );
};

export default AuthDropdown;
