import { getOrganizationsApi, getUserDataApi, loginApi } from '@/api/auth.api';
import fetchWrapper from '@/lib/fetchWrapper';
import { Organization, User } from '@/types/api/response/user';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import React, { createContext, useContext, useEffect } from 'react';
import { cookieNames } from '../constants';

// Centralized query keys
const QUERY_KEYS = {
  USER_DATA: 'userData',
  ORGANIZATIONS: 'organizations',
} as const;

interface GoogleResponse {
  credential: string;
}

interface AuthError {
  message: string;
  code?: string;
}

export interface AuthContextType {
  isAuthenticated: boolean;
  userFullName: string;
  organizationIcon: string;
  isStaff: boolean;
  organizationFocus: string;
  setOrganizationFocus: (organizationFocus: string) => void;
  staffOrganizations: Organization[];
  userOrganization: string;
  login: (email: string, password: string) => Promise<Response>;
  logout: () => Promise<void>;
  handleGoogleSuccess: (googleResponse: GoogleResponse) => Promise<void>;
  handleGoogleFailure: (error: AuthError) => void;
  refreshOrganizations: () => Promise<void>;
  getCSRFCookie: () => string | undefined;
  loading: boolean;
  userData: User | null;
  error: AuthError | null;
  emailVerification: (
    token: string,
    redirect_uri?: string,
  ) => Promise<{ message: string; redirect_uri?: string }>;
}

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const queryClient = useQueryClient();
  const [organizationFocus, setOrganizationFocus] = React.useState('');
  const [error, setError] = React.useState<AuthError | null>(null);

  // Persist organization focus in localStorage
  useEffect(() => {
    const savedFocus = localStorage.getItem('organizationFocus');
    if (savedFocus) setOrganizationFocus(savedFocus);
  }, []);

  useEffect(() => {
    if (organizationFocus) {
      localStorage.setItem('organizationFocus', organizationFocus);
    }
  }, [organizationFocus]);

  // User data query
  const {
    data: userData,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useQuery({
    queryKey: [QUERY_KEYS.USER_DATA],
    queryFn: getUserDataApi,
    retry: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // Organizations query
  const { data: organizations, isLoading: isOrgsLoading } = useQuery({
    queryKey: [QUERY_KEYS.ORGANIZATIONS],
    queryFn: getOrganizationsApi,
    enabled: !!userData,
    retry: false,
  });

  const isAuthenticated = !!userData && !isUserError;

  // Login mutation
  const loginMutation = useMutation({
    mutationFn: ({ email, password }: { email: string; password: string }) =>
      loginApi(email, password),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_DATA] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORGANIZATIONS] });
      setError(null);
    },
    onError: (error: AuthError) => {
      setError(error);
    },
  });

  // Logout mutation
  const logoutMutation = useMutation({
    mutationFn: async () => {
      await fetchWrapper.get('/logout');
      Cookies.remove(cookieNames.csrfToken);
    },
    onSuccess: () => {
      queryClient.removeQueries();
      localStorage.removeItem('organizationFocus');
    },
  });

  const emailVerificationMutation = useMutation({
    mutationFn: async ({ token, redirect_uri }: { token: string; redirect_uri?: string }) => {
      const response = await fetchWrapper.get<{
        message: string;
        redirect_uri?: string;
      }>(`/email-verification?token=${token}&redirect_uri=${redirect_uri}`);
      return response.data;
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_DATA] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORGANIZATIONS] });
      setError(null);
      return data;
    },
    onError: (error: AuthError) => {
      setError(error);
    },
  });
  const handleGoogleSuccess = async (googleResponse: GoogleResponse) => {
    try {
      await fetchWrapper.post('/google-login', { token: googleResponse.credential });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_DATA] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORGANIZATIONS] });
    } catch (error) {
      setError(error as AuthError);
    }
  };

  const handleGoogleFailure = (error: AuthError) => {
    setError(error);
  };

  const refreshOrganizations = async () => {
    await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORGANIZATIONS] });
  };

  const getCSRFCookie = () => Cookies.get(cookieNames.csrfToken);

  const loading = isUserLoading || isOrgsLoading || loginMutation.isPending;

  const derivedState = {
    userFullName: userData ? `${userData.first_name} ${userData.last_name}` : '',
    isStaff: userData?.is_staff ?? false,
    userOrganization: organizations?.entries[0]?.customername ?? '',
    organizationIcon: organizations?.entries[0]?.icon_url ?? '',
    staffOrganizations: organizations?.entries ?? [],
  };

  const value: AuthContextType = {
    isAuthenticated,
    ...derivedState,
    organizationFocus,
    setOrganizationFocus,
    login: (email: string, password: string) => loginMutation.mutateAsync({ email, password }),
    logout: logoutMutation.mutateAsync,
    handleGoogleSuccess,
    handleGoogleFailure,
    refreshOrganizations,
    getCSRFCookie,
    loading,
    userData,
    error: error || loginMutation.error || logoutMutation.error || null,
    emailVerification: (token: string, redirect_uri?: string) =>
      emailVerificationMutation.mutateAsync({ token, redirect_uri }),
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};
