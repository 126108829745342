import { SpecularLink } from '@/components/common/SpecularLink';
import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { cn } from '@/lib/utils';
import { getSeverityDisplay } from '@/pages/Issues/Issues';
import { VulnerabilitiesScanResults } from '@/types/api/response/investigations';
import ExpertChat from '@/v2/components/AIChat/ExpertChat';
import SmartAI from '@/v2/icons/Global/SmartAI';
import NavSpecularLogo from '@/v2/icons/nav-specular-logo.svg?react';
import { useQuery } from '@tanstack/react-query';
import { ArrowLeft, Clock, Calendar, ArrowDown, Terminal, FileCode, FileText } from 'lucide-react';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getVulnerabilityScanResultDetailsQuery } from '../../queries/investigation';
import { Separator } from '@/components/ui/separator';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';

function formatTimestamp(timestamp: string) {
  const date = DateTime.fromISO(timestamp);
  return date.toLocaleString({
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export default function DynamicDetails() {
  const { id } = useParams();
  const [isChatVisible, setIsChatVisible] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');
  const [showResponse, setShowResponse] = useState(false);

  const { data: vulnerability, isLoading } = useQuery<VulnerabilitiesScanResults>(
    getVulnerabilityScanResultDetailsQuery(id!),
  );

  const handleChatVisibilityChange = (isVisible: boolean) => {
    setIsChatVisible(isVisible);
  };

  if (isLoading) {
    return (
      <div className="p-4 space-y-4">
        <Skeleton className="h-8 w-64" />
        <Skeleton className="h-48 w-full" />
      </div>
    );
  }

  if (!vulnerability) return null;

  return (
    <div className="bg-background">
      <div className="py-6 px-6 container mx-auto 2xl:px-24">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-4">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => window.history.back()}
              className="text-muted-foreground hover:text-foreground"
            >
              <ArrowLeft className="mr-2 h-4 w-4" />
              Back
            </Button>
          </div>
        </div>

        <div className="relative">
          <ResizablePanelGroup direction="horizontal">
            <ResizablePanel defaultSize={55} minSize={50} maxSize={72}>
              <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
                <TabsList className="grid w-full grid-cols-3 mb-4">
                  <TabsTrigger value="overview">Overview</TabsTrigger>
                  <TabsTrigger value="analysis">
                    <SmartAI
                      className={cn('w-6 h-6 text-v2-orange hover:text-v2-orange-dark mr-1')}
                    />
                    Analysis
                  </TabsTrigger>
                  <TabsTrigger value="remediation">
                    <SmartAI
                      className={cn('w-6 h-6 text-v2-orange hover:text-v2-orange-dark mr-1')}
                    />
                    Remediation
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="overview">
                  <Card className="h-full w-full max-h-[calc(100vh-16rem)] overflow-y-auto">
                    <CardHeader>
                      <div className="grid grid-cols-1 md:grid-cols-[1fr,300px] gap-6 items-start">
                        <div className="space-y-4">
                          <h1 className="text-xl font-semibold">
                            {vulnerability.nuclei_template.name}
                          </h1>
                          <div>
                            <h3 className="text-base font-medium text-foreground mb-2">Severity</h3>
                            <div className="flex items-center gap-2">
                              {getSeverityDisplay({
                                severity: vulnerability.nuclei_template.severity,
                                showLabel: true,
                                showIcon: true,
                              })}
                            </div>
                          </div>
                          <div>
                            <h3 className="text-base font-medium mb-1">Description</h3>
                            <div>
                              <h3>
                                {vulnerability?.ai_analysis?.description ||
                                  vulnerability?.nuclei_template?.description}
                              </h3>
                            </div>
                          </div>
                        </div>

                        <Card className="h-fit">
                          <CardContent className="pt-6">
                            <div className="space-y-4">
                              <div className="flex items-center gap-2 align-middle justify-items-center justify-between">
                                <h3 className="text-sm font-medium text-foreground">Domain</h3>
                                <div className="mr-6">
                                  {vulnerability.http_asset.domain.domainname.length > 20
                                    ? `${vulnerability.http_asset.domain.domainname.slice(0, 20)}...`
                                    : vulnerability.http_asset.domain.domainname}
                                </div>
                              </div>
                              <div className="flex items-center gap-2 align-middle justify-items-center justify-between">
                                <h3 className="text-sm font-medium text-foreground">Port</h3>
                                <div className="mr-6">{vulnerability.http_asset.port}</div>
                              </div>
                              <div className="flex items-center gap-2 align-middle justify-items-center justify-between">
                                <h3 className="text-sm font-medium text-foreground">IP Address</h3>
                                <div className="mr-6">
                                  {vulnerability.http_asset.ipaddressv4 || 'N/A'}
                                </div>
                              </div>
                              <Separator />
                              <div className="space-y-2 text-sm text-foreground">
                                <div className="flex items-center gap-2">
                                  <Clock className="h-4 w-4" />
                                  <span>Last Seen {formatTimestamp(vulnerability.last_seen)}</span>
                                </div>
                                <div className="flex items-center gap-2">
                                  <Calendar className="h-4 w-4" />
                                  <span>
                                    Discovered {formatTimestamp(vulnerability.date_added)}
                                  </span>
                                </div>
                              </div>
                              <SpecularLink
                                resourceType="HTTPAsset"
                                id={vulnerability.http_asset.id.toString()}
                                title={'Asset Details'}
                              >
                                {vulnerability.http_asset.domain.domainname.length > 20
                                  ? `${vulnerability.http_asset.domain.domainname.slice(0, 20)}...`
                                  : vulnerability.http_asset.domain.domainname}
                                :{vulnerability.http_asset.port}
                              </SpecularLink>
                            </div>
                          </CardContent>
                        </Card>
                      </div>
                      {vulnerability?.ai_analysis?.impact && (
                        <div>
                          <h3 className="text-base font-medium mb-1">Impact</h3>
                          <div>
                            <MarkdownViewer markdown={vulnerability?.ai_analysis?.impact} />
                          </div>
                        </div>
                      )}
                      {vulnerability?.ai_analysis?.evidence && (
                        <div>
                          <h3 className="text-base font-medium mb-1">Evidence</h3>
                          <div>
                            <MarkdownViewer markdown={vulnerability?.ai_analysis?.evidence} />
                          </div>
                        </div>
                      )}
                    </CardHeader>
                    <CardContent className="space-y-6">
                      {vulnerability.nuclei_template.references.length > 0 && (
                        <Card className="min-w-0">
                          <CardHeader>
                            <CardTitle className="flex items-center gap-2">
                              <FileText className="h-5 w-5 text-red-500" />
                              References
                            </CardTitle>
                          </CardHeader>
                          <CardContent>
                            <div className="space-y-2">
                              {vulnerability.nuclei_template.references.map((ref, index) => (
                                <div key={index} className="flex items-start gap-2">
                                  <span className="text-muted-foreground">{index + 1}.</span>
                                  <a
                                    href={ref as string}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:text-blue-800 hover:underline break-all"
                                  >
                                    {ref as string}
                                  </a>
                                </div>
                              ))}
                            </div>
                          </CardContent>
                        </Card>
                      )}
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="analysis">
                  <Card className="h-full w-full max-h-[calc(100vh-16rem)] overflow-y-auto min-h-[calc(100vh-32rem)]">
                    <CardHeader></CardHeader>
                    <CardContent>
                      {vulnerability.ai_summary ? (
                        <div className="space-y-4">
                          <Card>
                            <CardHeader>
                              <CardTitle className="flex items-center gap-2">
                                <Terminal className="h-5 w-5 text-red-500" />
                                Validation Overview
                              </CardTitle>
                              <CardDescription>
                                {vulnerability.ai_exploit?.brief || vulnerability?.ai_summary}
                              </CardDescription>
                            </CardHeader>
                          </Card>

                          <Card className="border-0 shadow-none">
                            <CardContent className="pt-6">
                              <h4 className="text-base font-medium mb-2">CURL Command</h4>
                              <div className="rounded-lg">
                                <MarkdownViewer
                                  markdown={`\`\`\`bash\n${vulnerability.curl_command}\n\`\`\``}
                                />
                              </div>
                              {vulnerability.response && (
                                <div className="mt-4">
                                  <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => setShowResponse(!showResponse)}
                                    className="w-full justify-between bg-gray-50 hover:bg-gray-100"
                                  >
                                    Response
                                    <ArrowDown
                                      className={cn(
                                        'h-4 w-4 transition-transform',
                                        showResponse && 'rotate-180',
                                      )}
                                    />
                                  </Button>
                                  {showResponse && (
                                    <div className="mt-2">
                                      <MarkdownViewer
                                        markdown={`\`\`\`text\n${vulnerability.response}\n\`\`\``}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </CardContent>
                          </Card>
                          {vulnerability.ai_exploit?.steps && (
                            <Card className="min-w-0">
                              <CardHeader>
                                <CardTitle className="flex items-center gap-2">
                                  <Terminal className="h-5 w-5 text-red-500" />
                                  Validation Steps
                                </CardTitle>
                                <CardDescription>
                                  Step-by-step validation process with examples
                                </CardDescription>
                              </CardHeader>
                              <CardContent className="grid gap-6">
                                {vulnerability.ai_exploit.steps.map((step, idx) => (
                                  <div key={idx} className="grid gap-2 min-w-0">
                                    <div className="flex gap-3 items-start min-w-0">
                                      <Badge
                                        variant="outline"
                                        className="h-6 w-6 shrink-0 p-0 flex items-center justify-center bg-red-50 text-red-700 border-red-200"
                                      >
                                        {idx + 1}
                                      </Badge>
                                      <MarkdownViewer className="min-w-0" markdown={step} />
                                    </div>
                                  </div>
                                ))}
                              </CardContent>
                            </Card>
                          )}
                        </div>
                      ) : (
                        <div className="flex flex-col gap-4 items-center justify-center p-8">
                          <div className="text-gray-500">No AI Analysis available yet</div>
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="remediation">
                  <Card className="h-full w-full max-h-[calc(100vh-16rem)] overflow-y-auto min-h-[calc(100vh-32rem)]">
                    <CardHeader>
                      <CardTitle>Remediation</CardTitle>
                    </CardHeader>
                    <CardContent>
                      {vulnerability.ai_remediation ? (
                        <div className="grid gap-6">
                          <Alert variant="default" className="bg-orange-50 border-orange-200">
                            <Terminal className="h-4 w-4 text-orange-500" />
                            <AlertTitle className="text-orange-700">
                              Remediation Overview
                            </AlertTitle>
                            <AlertDescription className="text-orange-900">
                              {vulnerability?.ai_remediation?.brief}
                            </AlertDescription>
                          </Alert>

                          {vulnerability?.ai_remediation?.steps &&
                            vulnerability?.ai_remediation?.steps.length > 0 && (
                              <Card className="min-w-0">
                                <CardHeader>
                                  <CardTitle className="flex items-center gap-2">
                                    <FileCode className="h-5 w-5 text-orange-500" />
                                    Remediation Steps
                                  </CardTitle>
                                  <CardDescription>
                                    Follow these steps to address the vulnerability
                                  </CardDescription>
                                </CardHeader>
                                <CardContent className="grid gap-6">
                                  {vulnerability.ai_remediation.steps.map((step, idx) => (
                                    <div key={idx} className="grid gap-2 min-w-0">
                                      <div className="flex gap-3 items-start min-w-0">
                                        <Badge
                                          variant="outline"
                                          className="h-6 w-6 shrink-0 p-0 flex items-center justify-center bg-orange-50 text-orange-700 border-orange-200 mt-0.5"
                                        >
                                          {idx + 1}
                                        </Badge>
                                        <div className="flex-1 min-w-0">
                                          <MarkdownViewer markdown={step} />
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </CardContent>
                              </Card>
                            )}
                        </div>
                      ) : (
                        <div className="flex flex-col gap-4 items-center justify-center p-8">
                          <div className="text-gray-500">No Remediation Steps available yet</div>
                        </div>
                      )}
                    </CardContent>
                  </Card>
                </TabsContent>
              </Tabs>
            </ResizablePanel>
            {isChatVisible && (
              <>
                <ResizableHandle />
                <ExpertChat
                  context={[
                    { type: 'nuclei', id: vulnerability.nuclei_template.id },
                    { type: 'httpasset', id: vulnerability.http_asset.hostname },
                  ]}
                  chatId={`nuclei:${vulnerability.id}`}
                  title={vulnerability.nuclei_template.name}
                  onClose={() => handleChatVisibilityChange(false)}
                  onOpen={() => handleChatVisibilityChange(true)}
                />
              </>
            )}
          </ResizablePanelGroup>
          {!isChatVisible && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => handleChatVisibilityChange(true)}
              className="fixed bottom-4 right-4 flex items-center gap-2"
            >
              <NavSpecularLogo className="h-4 w-4" />
              Open Specular AI
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
