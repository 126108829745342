import { useAuth } from '@/Helpers/AuthContext';
import { AuthError, ValidationError } from '@/lib/fetchWrapper';
import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToast } from './use-toast';

export function useEmailVerification() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { emailVerification } = useAuth();
  const token = searchParams.get('token');
  const redirectUriParam = searchParams.get('redirect_uri');

  const verifyEmail = useCallback(async () => {
    if (!token) {
      toast({
        title: 'Error',
        description: 'Verification token is missing',
        variant: 'destructive',
      });
      navigate('/login');
      return;
    }

    try {
      await emailVerification(token);

      toast({
        title: 'Success',
        description: 'Email verified successfully',
        duration: 5000,
      });

      const redirectPath = redirectUriParam || '/issues';
      navigate(redirectPath);
    } catch (error) {
      let errorMessage = 'Failed to verify email';

      if (error instanceof AuthError || error instanceof ValidationError) {
        errorMessage =
          typeof (error.data as { error?: string })?.error === 'string'
            ? (error.data as { error: string }).error
            : errorMessage;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      toast({
        title: 'Error',
        description: errorMessage,
        variant: 'destructive',
        duration: 5000,
      });
      navigate('/login');
    }
  }, [token, redirectUriParam, navigate, toast]);

  return { verifyEmail };
}
