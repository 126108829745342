import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { MoreHorizontal } from 'lucide-react';
import { Link } from 'react-router-dom';

interface Action {
  label: string;
  onClick: () => void;
  icon?: React.ReactNode;
}

interface LinkWithActionProps {
  children: React.ReactNode;
  to: string;
  target?: string;
  title?: string;
  dataClickBypass?: boolean;
  actions: Action[];
  className?: string;
  showAsButton?: boolean;
  buttonIcon?: React.ReactNode;
  onClick?: () => void;
  buttonFontClass?: string;
}

export function LinkWithAction({
  children,
  to,
  target = '_self',
  title,
  dataClickBypass,
  actions,
  className,
  showAsButton = false,
  buttonIcon,
  onClick,
  buttonFontClass,
}: LinkWithActionProps) {
  return (
    <div className={cn('inline-flex items-center', className)}>
      <div className="group flex items-center gap-2">
        <Link
          to={to}
          target={target}
          className="hover:underline"
          title={title}
          data-click-bypass={dataClickBypass}
          onClick={onClick}
        >
          {showAsButton ? (
            <Button
              variant="outline"
              className={cn(
                'py-2 px-4 rounded-full',
                buttonFontClass ? buttonFontClass : 'font-normal',
              )}
            >
              <span className="hover:underline flex items-center gap-1">
                {buttonIcon}
                {children}
              </span>
            </Button>
          ) : (
            <span className="hover:underline flex items-center gap-1">{children}</span>
          )}
        </Link>
        <div className="flex items-center gap-1 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
          {actions.map((action) => (
            <Button
              key={action.label}
              onClick={action.onClick}
              variant="ghost"
              size="sm"
              className="inline-flex shrink-0 items-center gap-1 p-1"
              title={action.label}
              data-click-bypass={dataClickBypass}
            >
              {action.icon ?? <MoreHorizontal className="h-4 w-4" />}
              <span className="sr-only">{action.label}</span>
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}
