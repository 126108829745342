import { SuggestionsSelector } from '@/components/common/SuggestionsSelector';
import { getNVDSuggestions } from '../../api/nvd.api';
import { NVDVulnerability } from '../../types/api/response/nvd';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction.tsx';
import { Link as LinkIcon } from 'lucide-react';

interface VulnerabilitySelectorProps {
  placeholderText?: string;
  selectedVulnerabilityType: string | null;
  selectedVulnerability: any | null;
  onVulnerabilitySelect: (vulnerabilityType: string | null, vulnerability: any | null) => void;
  onClear: () => void;
  loading?: boolean;
  target?: string;
}

const getVulnerabilityDisplayValue = (
  vulnerability: NVDVulnerability | null,
  target: string | null,
) => {
  if (!vulnerability) return null;
  return (
    <>
      <LinkWithAction
        to={`/vulnerabilities/${vulnerability.cve_id}?from=/issues`}
        dataClickBypass={true}
        title="View CVE details"
        actions={[]}
        showAsButton={true}
        buttonIcon={<LinkIcon className="h-4 w-4" />}
        target={target}
      >
        {vulnerability.cve_id}
      </LinkWithAction>
    </>
  );
};

const getVulnerabilityDisplaySuggestion = (vulnerability: NVDVulnerability) => {
  return vulnerability.cve_id || 'Unknown CVE';
};

const fetchVulnerabilitySuggestions = async (vulnerabilityType: string, query: string) => {
  const response = await getNVDSuggestions(query);
  return response.entries || [];
};

export const VulnerabilitySelector = ({
  placeholderText,
  selectedVulnerabilityType,
  selectedVulnerability,
  onVulnerabilitySelect,
  onClear,
  loading,
}: VulnerabilitySelectorProps) => {
  const vulnerabilityTypes = ['NVDVulnerabilities'];

  return (
    <SuggestionsSelector
      placeholderText={placeholderText || 'Vulnerability'}
      modelTypes={vulnerabilityTypes}
      selectedModelType={selectedVulnerabilityType}
      selectedItem={selectedVulnerability}
      onSelect={onVulnerabilitySelect}
      onClear={onClear}
      fetchSuggestions={fetchVulnerabilitySuggestions}
      getDisplayValue={getVulnerabilityDisplayValue}
      getDisplaySuggestion={getVulnerabilityDisplaySuggestion}
      loading={loading}
    />
  );
};
