import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { getSeverityDisplay } from '@/pages/Issues/Issues';
import { Issue, ISSUE_STATUSES, ISSUE_STATUSES_NAMES } from '@/types/api/response/issues';
import SmartAI from '@/v2/icons/Global/SmartAI';
import { useVulnerabilityDetails } from '@/v2/queries/vulnerabilities';
import { getCVSSScoreColor } from '@/v2/utils';
import { ArrowLeft, FileCode, FileText, Terminal } from 'lucide-react';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

export default function VulnerabilityDetails() {
  const [activeTab, setActiveTab] = useState('overview');
  const [assetFilter, setAssetFilter] = useState<'all' | 'solved' | 'unsolved'>('all');
  const { id } = useParams();
  const { data } = useVulnerabilityDetails(id);
  const filteredAssets = data?.http_assets.filter((asset) => {
    if (assetFilter === 'all') return true;
    if (assetFilter === 'solved') return !asset.vulnerability_status.detected_last_scan;
    return asset.vulnerability_status.detected_last_scan;
  });

  return (
    <>
      <div className="container mx-auto p-6">
        {window.history.length > 1 && (
          <button
            onClick={() => {
              const previousUrl = document.referrer;
              if (previousUrl && previousUrl.includes(window.location.origin)) {
                window.history.back();
              } else {
                window.location.href = '/investigation/passive';
              }
            }}
            className="flex items-center gap-2 text-muted-foreground hover:text-foreground mb-6 transition-colors"
          >
            <ArrowLeft className="h-4 w-4" />
            Back
          </button>
        )}
        <div className="mb-6">
          <h1 className="text-3xl font-bold mb-2">{data?.cve_id}</h1>
          <p className="text-gray-700">{data?.ai_analysis.brief || data?.description}</p>
        </div>
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="overview">Overview</TabsTrigger>
            <TabsTrigger value="exploit">
              <SmartAI className={cn('w-6 h-6 text-v2-orange hover:text-v2-orange-dark mr-1')} />
              Exploit
            </TabsTrigger>
            <TabsTrigger value="remediation">
              <SmartAI className={cn('w-6 h-6 text-v2-orange hover:text-v2-orange-dark mr-1')} />
              Remediation
            </TabsTrigger>
          </TabsList>
          <TabsContent value="overview">
            <div className="flex flex-col gap-4">
              <Card>
                <CardHeader>
                  <CardTitle>Vulnerability Overview</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <h3 className="font-semibold mb-2">Vendor</h3>
                      <span className="text-gray-700">{data?.cpe_uris.vendor}</span>
                    </div>
                    <div>
                      <h3 className="font-semibold mb-2">Product</h3>
                      <span className="text-gray-700">{data?.cpe_uris.product}</span>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <h3 className="font-semibold mb-2">Description</h3>
                    <p className="mb-4">{data?.ai_analysis.description}</p>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <h3 className="font-semibold mb-2">Risk Score</h3>
                      <span
                        style={{ color: getCVSSScoreColor(data?.cvss3_base_score ?? '-') }}
                        className="text-2xl font-bold"
                      >
                        {data?.cvss3_base_score ?? 'N/A'}
                      </span>
                    </div>
                    <div>
                      <h3 className="font-semibold mb-2">CVSSv3 Exploitability Score</h3>
                      <span
                        style={{
                          color: getCVSSScoreColor(data?.cvss3_exploitability_score ?? '-'),
                        }}
                        className="text-2xl font-bold"
                      >
                        {data?.cvss3_exploitability_score ?? 'N/A'}
                      </span>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <div className="grid grid-cols-2 gap-4">
                <Card>
                  <CardHeader>
                    <CardTitle>Affected Assets</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex flex-col gap-4">
                      <div className="flex gap-2">
                        <button
                          onClick={() => setAssetFilter('all')}
                          className={`px-3 py-1 rounded-full text-xs font-medium transition-colors ${
                            assetFilter === 'all'
                              ? 'bg-primary text-primary-foreground'
                              : 'bg-secondary hover:bg-secondary/80'
                          }`}
                        >
                          All
                        </button>
                        <button
                          onClick={() => setAssetFilter('solved')}
                          className={`px-3 py-1 rounded-full text-xs font-medium transition-colors ${
                            assetFilter === 'solved'
                              ? 'bg-primary text-primary-foreground'
                              : 'bg-secondary hover:bg-secondary/80'
                          }`}
                        >
                          Solved
                        </button>
                        <button
                          onClick={() => setAssetFilter('unsolved')}
                          className={`px-3 py-1 rounded-full text-xs font-medium transition-colors ${
                            assetFilter === 'unsolved'
                              ? 'bg-primary text-primary-foreground'
                              : 'bg-secondary hover:bg-secondary/80'
                          }`}
                        >
                          Unsolved
                        </button>
                      </div>
                      <ScrollArea className="max-h-[200px] overflow-y-auto">
                        <div className="flex flex-col gap-2">
                          {filteredAssets?.map((asset) => (
                            <div
                              key={asset.id}
                              className="flex items-center justify-between p-2.5 bg-white border border-gray-200 hover:border-gray-300 hover:bg-accent/5 rounded-md transition-colors"
                            >
                              <Link
                                to={`/assets/${asset.id}`}
                                target="_self"
                                rel="noreferrer"
                                className="text-sm text-foreground/80 hover:text-foreground"
                              >
                                {asset.hostname}
                              </Link>
                              {!asset.vulnerability_status.detected_last_scan && (
                                <Badge
                                  variant="outline"
                                  className="bg-green-50 border-green-300 text-green-700"
                                >
                                  Patched
                                </Badge>
                              )}
                            </div>
                          ))}
                          {filteredAssets?.length === 0 && (
                            <div className="text-center text-gray-500 py-4">
                              No {assetFilter === 'all' ? '' : assetFilter} assets found
                            </div>
                          )}
                        </div>
                      </ScrollArea>
                    </div>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle>Issues</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex flex-col gap-4 overflow-y-auto max-h-[300px]">
                      {data?.issues?.length === 0 ? (
                        <div className="text-center text-gray-500 py-4">No issues found</div>
                      ) : (
                        data?.issues?.map((issue, index) => (
                          <div
                            key={index}
                            className="p-4 bg-card border rounded-lg hover:border-border/80 transition-colors"
                          >
                            <div className="flex flex-col sm:flex-row sm:items-start justify-between gap-4">
                              <div className="flex-1 min-w-0">
                                <div className="flex flex-wrap items-start gap-2 mb-2">
                                  <TooltipProvider>
                                    <Tooltip>
                                      <TooltipTrigger asChild>
                                        <Link
                                          to={`/issues/${issue.id}?from=ipaddress/${data?.id}`}
                                          target="_self"
                                          className="font-semibold hover:underline cursor-pointer line-clamp-2"
                                        >
                                          {issue.title}
                                        </Link>
                                      </TooltipTrigger>
                                      <TooltipContent side="top" className="max-w-[400px]">
                                        <p className="break-words">{issue.title}</p>
                                      </TooltipContent>
                                    </Tooltip>
                                  </TooltipProvider>
                                  <div className="shrink-0">
                                    {getSeverityDisplay({
                                      severity: issue.severity as Issue['severity'],
                                      showLabel: true,
                                      showIcon: true,
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-row sm:flex-col items-center sm:items-end gap-2 shrink-0">
                                <div className="flex flex-wrap items-center gap-2">
                                  <Badge
                                    className={
                                      ISSUE_STATUSES[issue.status as keyof typeof ISSUE_STATUSES]
                                    }
                                  >
                                    {
                                      ISSUE_STATUSES_NAMES[
                                        issue.status as keyof typeof ISSUE_STATUSES_NAMES
                                      ]
                                    }
                                  </Badge>
                                  {issue.is_resolved && (
                                    <Badge variant="outline" className="bg-green-50">
                                      Resolved
                                    </Badge>
                                  )}
                                </div>
                                <time className="text-sm text-muted-foreground whitespace-nowrap">
                                  {DateTime.fromISO(issue.created_at).toRelative()}
                                </time>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>
              {data?.ai_advisory_references && data.ai_advisory_references.length > 0 && (
                <Card>
                  <CardHeader>
                    <CardTitle>References</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-2">
                      {data.ai_advisory_references.map((reference, index) => (
                        <div key={index} className="flex items-start gap-2">
                          <span className="text-muted-foreground">{index + 1}.</span>
                          <a
                            href={reference}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:text-blue-800 hover:underline break-all"
                          >
                            {reference}
                          </a>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>
          </TabsContent>
          <TabsContent value="exploit">
            <div className="flex flex-col gap-4">
              <Card>
                <CardHeader>
                  <CardTitle>AI Exploit Analysis</CardTitle>
                </CardHeader>
                <CardContent>
                  {data?.ai_validation ? (
                    <div className="grid gap-6">
                      {data?.ai_validation.steps && (
                        <Card>
                          <CardHeader>
                            <CardTitle className="flex items-center gap-2">
                              <Terminal className="h-5 w-5 text-red-500" />
                              Exploit Overview
                            </CardTitle>
                            <CardDescription>{data?.ai_validation.steps?.brief}</CardDescription>
                          </CardHeader>
                        </Card>
                      )}

                      {data?.ai_validation.code && (
                        <Card className="min-w-0">
                          <CardHeader>
                            <CardTitle className="flex items-center gap-2">
                              <FileCode className="h-5 w-5 text-red-500" />
                              Proof of Concept
                            </CardTitle>
                            <CardDescription>
                              Step-by-step exploitation process with code examples
                            </CardDescription>
                          </CardHeader>
                          <CardContent className="grid gap-6">
                            {data?.ai_validation.steps?.steps.map((step, idx) => (
                              <div key={idx} className="grid gap-2 min-w-0">
                                <div className="flex gap-3 items-start min-w-0">
                                  <Badge
                                    variant="outline"
                                    className="h-6 w-6 shrink-0 p-0 flex items-center justify-center bg-red-50 text-red-700 border-red-200"
                                  >
                                    {idx + 1}
                                  </Badge>
                                  <MarkdownViewer className="min-w-0" markdown={step} />
                                </div>
                              </div>
                            ))}
                          </CardContent>
                        </Card>
                      )}

                      <Card className="min-w-0">
                        <CardHeader>
                          <CardTitle className="flex items-center gap-2">
                            <FileText className="h-5 w-5 text-red-500" />
                            Exploit Code
                          </CardTitle>
                          <CardDescription>Ready-to-use proof of concept code</CardDescription>
                        </CardHeader>
                        <CardContent>
                          <MarkdownViewer
                            markdown={`\`\`\`python\n${data?.ai_validation.code}\n\`\`\``}
                          />
                        </CardContent>
                      </Card>

                      {data?.ai_validation_references && data.ai_validation_references.length > 0 && (
                        <Card className="min-w-0">
                          <CardHeader>
                            <CardTitle className="flex items-center gap-2">
                              <FileText className="h-5 w-5 text-red-500" />
                              References
                            </CardTitle>
                          </CardHeader>
                          <CardContent>
                            <div className="space-y-2">
                              {data.ai_validation_references.map((reference, index) => (
                                <div key={index} className="flex items-start gap-2">
                                  <span className="text-muted-foreground">{index + 1}.</span>
                                  <a
                                    href={reference}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:text-blue-800 hover:underline break-all"
                                  >
                                    {reference}
                                  </a>
                                </div>
                              ))}
                            </div>
                          </CardContent>
                        </Card>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-col gap-4 items-center justify-center p-8">
                      <div className="text-gray-500">No Exploit available</div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
          </TabsContent>
          <TabsContent value="remediation">
            <div className="flex flex-col gap-4">
              <Card>
                <CardHeader>
                  <CardTitle>AI Remediation</CardTitle>
                </CardHeader>
                <CardContent>
                  {data?.ai_remediation ? (
                    <div className="grid gap-6">
                      <Alert variant="default" className="bg-orange-50 border-orange-200">
                        <Terminal className="h-4 w-4 text-orange-500" />
                        <AlertTitle className="text-orange-700">Remediation Overview</AlertTitle>
                        <AlertDescription className="text-orange-900">
                          {data?.ai_remediation.brief}
                        </AlertDescription>
                      </Alert>

                      <Card className="min-w-0">
                        <CardHeader>
                          <CardTitle className="flex items-center gap-2">
                            <FileCode className="h-5 w-5 text-orange-500" />
                            Remediation Steps
                          </CardTitle>
                          <CardDescription>
                            Follow these steps to address the vulnerability
                          </CardDescription>
                        </CardHeader>
                        <CardContent className="grid gap-6">
                          {data?.ai_remediation.steps?.map((step, idx) => (
                            <div key={idx} className="grid gap-2 min-w-0">
                              <div className="flex gap-3 items-start min-w-0">
                                <Badge
                                  variant="outline"
                                  className="h-6 w-6 shrink-0 p-0 flex items-center justify-center bg-orange-50 text-orange-700 border-orange-200"
                                >
                                  {idx + 1}
                                </Badge>
                                <MarkdownViewer className="min-w-0" markdown={step} />
                              </div>
                            </div>
                          ))}
                        </CardContent>
                      </Card>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-4 items-center justify-center p-8">
                      <div className="text-gray-500">No Remediation available</div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
}
