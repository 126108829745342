import { GetVulnerabilitiesScanResultsParams } from '@/api/investigations.api';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { getSeverityDisplay } from '@/pages/Issues/Issues.tsx';
import { ScannedVulnerabilityLight } from '@/types/api/response/investigations';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Filter, Globe } from 'lucide-react';
import { DateTime } from 'luxon';
import { parseAsArrayOf, parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import { useNavigate } from 'react-router-dom';
import {
  getVulnerabilitiesNucleiASNOverviewQuery,
  getVulnerabilitiesScanResultsQuery,
  usePrefetchVulnerabilityScanResultDetailsQuery,
} from '../../queries/investigation';

export default function Dynamic() {
  const navigate = useNavigate();
  const prefetchDetails = usePrefetchVulnerabilityScanResultDetailsQuery();
  const [selectedASNs, setSelectedASNs] = useQueryState(
    'selectedASNs',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [selectedSeverities, setSelectedSeverities] = useQueryState(
    'selectedSeverities',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [domainFilter, setDomainFilter] = useQueryState('domainFilter', {
    defaultValue: '',
  });
  const [domainFilterType, setDomainFilterType] = useQueryState('domainFilterType', {
    defaultValue: 'startswith',
  });

  const getVulnerabilitiesScanResultsQueryParams: GetVulnerabilitiesScanResultsParams = {
    page,
    asn: selectedASNs.join(','),
    domainname: domainFilter,
    filter_type: domainFilterType,
    severity: selectedSeverities.join(','),
  };

  const { data: ScanResultsData, isLoading } = useQuery(
    getVulnerabilitiesScanResultsQuery(getVulnerabilitiesScanResultsQueryParams),
  );

  const { data: ASNOverviewData = [] } = useQuery(getVulnerabilitiesNucleiASNOverviewQuery());

  const formatTimestamp = (timestamp: string) => {
    const date = DateTime.fromISO(timestamp);
    return date.toLocaleString({
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const descriptionCell = (description: string) => {
    return description && description.trim() !== '' ? description : 'N/A';
  };

  const handleSelectDomain = (domain: string) => {
    setDomainFilter(domain);
    setPage(1);
  };

  const handleSelectDomainFilterType = (type: string) => {
    setDomainFilterType(type);
    setPage(1);
  };

  const handleSelectASN = (asn: string[]) => {
    setSelectedASNs(asn);
    setPage(1);
  };

  const handleSelectSeverity = (severity: string[]) => {
    setSelectedSeverities(severity);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const clearAllFilters = () => {
    setSelectedSeverities(null);
    setDomainFilter('');
    setDomainFilterType('startswith');
    setSelectedASNs([]);
    setPage(1);
  };

  const handleRowHover = (id: string) => {
    prefetchDetails(id);
  };

  const columns: ColumnDef<ScannedVulnerabilityLight>[] = [
    {
      accessorKey: 'domain',
      header: 'Domain',
      cell: ({ row }) => (
        <LinkWithAction
          to={`/assets/${row.original.http_asset_id}?from=investigation/dynamic`}
          dataClickBypass={true}
          title="View Asset details"
          actions={[
            {
              label: 'Set Domain as Filter',
              onClick: () => handleSelectDomain(row.original.domain),
              icon: <Filter />,
            },
          ]}
          showAsButton={true}
          buttonIcon={<Globe className="h-4 w-4" />}
        >
          {row.original.domain}
        </LinkWithAction>
      ),
    },
    {
      header: 'Port',
      accessorKey: 'port',
    },
    {
      accessorKey: 'name',
      header: () => <div className="text-center">Name</div>,
      cell: ({ row }) => <div className="text-center">{row.original.name}</div>,
    },
    {
      accessorKey: 'description',
      header: () => <div className="text-center">Description</div>,
      cell: ({ row }) => (
        <div className="max-w-[400px]">{descriptionCell(row.original.description)}</div>
      ),
    },
    {
      accessorKey: 'severity',
      header: () => <div className="text-center">Severity</div>,
      cell: ({ row }) => (
        <div className="flex justify-center items-center">
          {getSeverityDisplay({
            severity: row.original.severity,
            showLabel: true,
            showIcon: false,
          })}
        </div>
      ),
    },
    {
      accessorKey: 'first_identified',
      header: () => <div className="text-center">Discovered</div>,
      cell: ({ row }) => (
        <div className="whitespace-nowrap text-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span className="text-xs">{formatTimestamp(row.original.first_identified)}</span>
              </TooltipTrigger>
              <TooltipContent>
                <p>When the vulnerability was first identified by Specular.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ),
    },
    {
      accessorKey: 'last_seen',
      header: () => <div className="text-center">Last Seen</div>,
      cell: ({ row }) => (
        <div className="whitespace-nowrap text-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span className="text-xs">{formatTimestamp(row.original.last_seen)}</span>
              </TooltipTrigger>
              <TooltipContent>
                <p>When the service was last observed by Specular.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ),
    },
  ];

  const availableFilters: AvailableFilter[] = [
    {
      label: 'Severity',
      key: 'severity',
      type: 'multiSelect',
      setState: handleSelectSeverity,
      state: selectedSeverities,
      placeholder: 'Search by severity',
      options: [
        {
          label: 'Critical',
          value: 'critical',
        },
        {
          label: 'High',
          value: 'high',
        },
        {
          label: 'Medium',
          value: 'medium',
        },
        {
          label: 'Low',
          value: 'low',
        },
        {
          label: 'Info',
          value: 'info',
        },
      ],
      onClear: () => {
        handleSelectSeverity(null);
      },
    },
    {
      label: 'Domain',
      key: 'domain',
      type: 'domainSearch',
      setState: handleSelectDomain,
      state: domainFilter,
      setValueType: handleSelectDomainFilterType,
      valueType: domainFilterType,
      placeholder: 'Search by domain',
      options: [
        {
          label: 'starts with',
          value: 'startswith',
        },
        {
          label: 'contains',
          value: 'contains',
        },
      ],
      defaultValue: 'startswith',
    },
    {
      label: 'ASN',
      key: 'asn',
      type: 'multiSelect',
      setState: handleSelectASN,
      state: selectedASNs,
      placeholder: 'Filter by ASN',
      options: ASNOverviewData.map((asn) => ({
        label: `${asn.owner ? asn.owner : 'Unknown'} (ASN: ${asn.asn ? asn.asn.toString() : 'Unknown'}) - ${asn.count} entries`,
        value: asn.asn ? asn.asn.toString() : null,
      })),
      onClear: () => {},
    },
  ];

  return (
    <div className="p-4 max-w-[2000px] mx-auto">
      <UniversalFilter
        filters={availableFilters}
        clearAllFilters={clearAllFilters}
        className="mb-4"
      />
      <DataTable
        columns={columns}
        data={ScanResultsData?.entries}
        loading={isLoading}
        currentPage={page}
        totalPages={ScanResultsData?.total_pages}
        onPageChange={handlePageChange}
        totalEntries={ScanResultsData?.total_count}
        error={null}
        maxWidth="w-full"
        onRowClick={(row) => navigate(`/investigation/dynamic/${row.id}`)}
        onRowHover={(row) => handleRowHover(row.id)}
      />
    </div>
  );
}
