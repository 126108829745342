import { createLogger } from '@/common/lib/logger';
import { useAuth } from '@/Helpers/AuthContext';
import { QueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  createBrowserRouter,
  ErrorResponse,
  Navigate,
  Outlet,
  useNavigate,
  useRouteError,
} from 'react-router-dom';
import IssueDetails from '../../pages/Issues/IssueDetails';
import LayoutHeader from '../components/LayoutHeader/LayoutHeader';
import { getAssetsListQuery } from '../queries/assets';
import AnonymousLayout from './AnonymousLayout';
import { AddAssetDialog } from './Assets/AddAssetDialog';
import AssetDetails from './Assets/AssetDetails';
import Assets from './Assets/Assets';
import Domains from './Assets/Domains';
import Services from './Assets/Services';
import ChangePassword from './Auth/ChangePassword';
import EmailVerification from './Auth/EmailVerification';
import ForgotPassword from './Auth/ForgotPassword';
import Login from './Auth/Login';
import Logout from './Auth/Logout';
import Register from './Auth/Register';
import ResetPassword from './Auth/ResetPassword';
import HTTPAnalizer from './HTTPAnalizer/HTTPAnalizer';
import Dynamic from './Investigation/Dynamic';
import DynamicDetails from './Investigation/DynamicDetails';
import Passive from './Investigation/Pasive';
import PassiveDetails from './Investigation/PasiveDetails';
import { IPAdressDetailsView } from './IPAdress/IPAdressDetailsView';
import Issues from './Issues/Issues';
import NewIssuePage from './Issues/NewIssuePage';
import LoggedInLayout from './LoggedInLayout';
import Notes from './Notes/Notes';
import UserSettings from './User/UserSettings';
import VulnerabilityDetails from './Vulnerabilities/VulnerabilitiesDetails';

const log = createLogger('router');

const ErrorDisplayComponent = ({ error }: { error: Error }) => {
  log('App error', error);

  return <div className="red">{error.message}</div>;
};

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { isAuthenticated, loading } = useAuth();

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/login');
    }
  }, [loading, isAuthenticated, navigate]);

  if (loading) {
    return <div />;
  }

  if (!isAuthenticated) {
    return null;
  }

  return <ErrorBoundary FallbackComponent={ErrorDisplayComponent}>{children}</ErrorBoundary>;
};

const RouterErrorBoundary = () => {
  const error = useRouteError() as ErrorResponse;

  return <ErrorDisplayComponent error={new Error(error?.data ?? 'Unknown error')} />;
};

const queryClient = new QueryClient();

const prefetchAssetsList = () => {
  queryClient.prefetchQuery(getAssetsListQuery());
};

export const router = createBrowserRouter([
  {
    element: <AnonymousLayout />,
    errorElement: <RouterErrorBoundary />,
    children: [
      {
        path: '/register',
        Component: Register,
      },
      {
        path: '/login',
        Component: Login,
      },
      {
        path: '/forgot-password',
        Component: ForgotPassword,
      },
      {
        path: '/logout',
        Component: Logout,
      },
      {
        path: '/password-reset',
        Component: ResetPassword,
      },
      {
        path: '/change-password',
        Component: ChangePassword,
      },
      {
        path: '/email-verification',
        Component: EmailVerification,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <LoggedInLayout />
      </ProtectedRoute>
    ),
    errorElement: <RouterErrorBoundary />,
    children: [
      {
        path: '/',
        element: (
          <>
            <LayoutHeader links={[{ label: 'Issues', to: '/' }]} />
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/issues" replace />,
          },
        ],
      },
      {
        path: '/issues',
        element: (
          <>
            <LayoutHeader links={[{ label: 'Issues', to: '/issues' }]} />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '/issues',
            Component: Issues,
          },
          {
            path: '/issues/new',
            Component: NewIssuePage,
          },
          {
            path: '/issues/:id',
            Component: IssueDetails,
          },
        ],
      },
      {
        path: '/assets',
        element: (
          <>
            <LayoutHeader
              links={[
                { label: 'HTTP Services', to: '/assets', prefetch: prefetchAssetsList },
                { label: 'All Services', to: '/assets/services' },
                { label: 'Domains', to: '/assets/domains' },
              ]}
              actions={[{ component: <AddAssetDialog /> }]}
            />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '/assets',
            Component: Assets,
            index: true,
          },
          {
            path: 'services',
            Component: Services,
          },
          {
            path: ':id',
            Component: AssetDetails,
          },
          {
            path: 'domains',
            Component: Domains,
          },
        ],
      },
      {
        path: '/notes',
        element: (
          <>
            <LayoutHeader links={[{ label: 'Notes', to: '/notes' }]} />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '/notes',
            Component: Notes,
          },
        ],
      },
      {
        path: '/http_analyzer',
        Component: HTTPAnalizer,
      },
      {
        path: '/investigation',
        element: (
          <>
            <LayoutHeader
              links={[
                { label: 'Passive Scanning', to: '/investigation/passive' },
                { label: 'Dynamic Scanning', to: '/investigation/dynamic' },
              ]}
            />
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/investigation/passive" replace />,
          },
          {
            path: 'passive',
            children: [
              {
                index: true,
                Component: Passive,
              },
              {
                path: ':id',
                Component: PassiveDetails,
              },
            ],
          },
          {
            path: 'dynamic',
            children: [
              {
                index: true,
                Component: Dynamic,
              },
              {
                path: ':id',
                Component: DynamicDetails,
              },
            ],
          },
        ],
      },
      {
        element: (
          <>
            <LayoutHeader links={[]} />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '/ipaddress/:id',
            Component: IPAdressDetailsView,
          },
        ],
      },
      {
        path: '/settings',
        element: (
          <>
            <LayoutHeader links={[{ label: 'Settings', to: '/settings' }]} />
            <Outlet />
          </>
        ),
        children: [
          {
            path: '/settings',
            Component: UserSettings,
          },
        ],
      },
      {
        path: '/vulnerabilities/:id',
        Component: VulnerabilityDetails,
        children: [
          {
            path: '/vulnerabilities/:id',
            Component: VulnerabilityDetails,
          },
        ],
      },
    ],
  },
]);
