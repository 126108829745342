import { buildFormData, ImageMap } from '../hooks/useAttachedImages';
import { fetchWrapper } from '../lib/fetchWrapper';
import { Issue, IssuesResponse } from '../types/api/response/issues';

export type NewIssuePayload = {
  title: string;
  description: string;
  severity: 'informational' | 'low' | 'medium' | 'high' | 'critical';
  affected_resource_model_type?: string | null;
  affected_resource_object_id?: number | null;
  vuln_model_type?: string | null;
  vuln_object_id?: number | null;
  assignedToId?: string;
  status: 'draft' | 'new' | 'acknowledged' | 'in_progress' | 'mitigated' | 'resolved' | 'ignored';
  _uploaded_screenshots?: ImageMap;
};

const ISSUE_FIELD_TYPES = {
  assigned_to_id: 'integer',
  affected_resource_object_id: 'integer',
  vuln_object_id: 'integer',
  title: 'string',
  description: 'string',
  severity: 'string',
  status: 'string',
  affected_resource_model_type: 'string',
  vuln_model_type: 'string',
  _uploaded_screenshots: 'image_map',
} as const;

export type UpdateIssuePayload = Partial<{
  title: string;
  description: string;
  severity: 'informational' | 'low' | 'medium' | 'high' | 'critical';
  assigned_to_id: number;
  status: string;
  affected_resource_model_type: string;
  affected_resource_object_id: number;
  vuln_model_type: string;
  vuln_object_id: number;
  _uploaded_screenshots: ImageMap;
}>;

export const createIssue = async (issue: NewIssuePayload): Promise<Issue> => {
  const formData = buildFormData('_uploaded_screenshots', issue._uploaded_screenshots || {});

  formData.append('severity', issue.severity);
  formData.append('title', issue.title);
  formData.append('description', issue.description);
  formData.append('assigned_to_id', issue.assignedToId);
  formData.append('status', issue.status || 'new');

  if (issue.affected_resource_model_type) {
    formData.append('affected_resource_model_type', issue.affected_resource_model_type);

    if (issue.affected_resource_object_id) {
      formData.append('affected_resource_object_id', issue.affected_resource_object_id.toString());
    }
  }

  if (issue.vuln_model_type) {
    formData.append('vuln_model_type', issue.vuln_model_type);

    if (issue.vuln_object_id) {
      formData.append('vuln_object_id', issue.vuln_object_id.toString());
    }
  }

  const { data } = await fetchWrapper.post<Issue>('/issues', formData);

  return data;
};

export type getIssuesParams = {
  page?: number;
  limit?: number;
  created_at?: string;
  status?: string;
  severity?: string;
  assigned_to?: number | string;
  affected_resource_id?: number;
  affected_resource_type?: string;
  created_at_filter_operator?: string;
};

export const getIssue = async (issueId: number): Promise<Issue> => {
  const { data } = await fetchWrapper.get<Issue>(`/issues/${issueId}`);
  return data;
};

export const getIssues = async (params: getIssuesParams = {}): Promise<IssuesResponse> => {
  const { data } = await fetchWrapper.get<IssuesResponse>('/issues', {
    params: {
      page: params.page ?? 1,
      limit: params.limit ?? 10,
      status: params.status ?? '',
      severity: params.severity ?? '',
      assigned_to: params.assigned_to ?? '',
      affected_resource_id: params.affected_resource_id ?? '',
      affected_resource_type: params.affected_resource_type ?? '',
      created_at_filter_operator: params.created_at_filter_operator ?? '',
      created_at: params.created_at ?? '',
    },
  });
  return data;
};

export const updateIssue = async (issueId: number, updates: UpdateIssuePayload): Promise<Issue> => {
  const formData = buildFormData('_uploaded_screenshots', updates._uploaded_screenshots || {});

  Object.entries(updates).forEach(([key, value]) => {
    if (key !== '_uploaded_screenshots' && value != null) {
      const fieldType = ISSUE_FIELD_TYPES[key as keyof typeof ISSUE_FIELD_TYPES];

      if (fieldType === 'integer') {
        formData.append(key, parseInt(String(value)).toString());
      } else {
        formData.append(key, String(value));
      }
    }
  });

  const { data } = await fetchWrapper.patch<Issue>(`/issues/${issueId}`, formData);
  return data;
};

export const deleteIssue = async (issueId: number): Promise<void> => {
  await fetchWrapper.delete(`/issues/${issueId}`);
};

export default createIssue;
