import { CheckIcon } from 'lucide-react';
import * as React from 'react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import { cn } from '@/lib/utils';

interface FilterMultiSelectProps {
  options: {
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
  value: string[];
  onValueChange: (value: string[]) => void;
  placeholder?: string;
}

export function FilterMultiSelect({
  options,
  value,
  onValueChange,
  placeholder = 'Search...',
}: FilterMultiSelectProps) {
  const toggleOption = (optionValue: string) => {
    const newValue = value.includes(optionValue)
      ? value.filter((v) => v !== optionValue)
      : [...value, optionValue];
    onValueChange(newValue);
  };

  const toggleAll = () => {
    if (value.length === options.length) {
      onValueChange([]);
    } else {
      onValueChange(options.map((option) => option.value));
    }
  };

  return (
    <Command className="w-full">
      <CommandInput placeholder={placeholder} />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          <CommandItem onSelect={toggleAll}>
            <div
              className={cn(
                'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                value.length === options.length
                  ? 'bg-primary text-primary-foreground'
                  : 'opacity-50 [&_svg]:invisible',
              )}
            >
              <CheckIcon className={cn('h-4 w-4')} />
            </div>
            <span>Select All</span>
          </CommandItem>
          {options.map((option) => {
            const isSelected = value.includes(option.value);
            return (
              <CommandItem key={option.value} onSelect={() => toggleOption(option.value)}>
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    isSelected
                      ? 'bg-primary text-primary-foreground'
                      : 'opacity-50 [&_svg]:invisible',
                  )}
                >
                  <CheckIcon className={cn('h-4 w-4')} />
                </div>
                {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                <span>{option.label}</span>
              </CommandItem>
            );
          })}
        </CommandGroup>
        {value.length > 0 && (
          <>
            <CommandSeparator />
            <CommandGroup>
              <CommandItem onSelect={() => onValueChange([])}>Clear selections</CommandItem>
            </CommandGroup>
          </>
        )}
      </CommandList>
    </Command>
  );
}
