import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useAssetQueries } from '@/hooks/useAssetQueries';
import { AssetWithDetails } from '@/types/api/response/assets';
import { AIActionsMenu } from '@/v2/components/AIActionsMenu/AIActionsMenu';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { ElementWithActions } from '@/v2/components/ElementWithActions/ElementWithActions';
import { LinkWithAction } from '@/v2/components/LinkWithAction/LinkWithAction';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import { usePrefetchAssetDetails, usePrefetchAssetHistoryQuery } from '@/v2/queries/assets';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ArrowDown, ArrowUp, ArrowUpDown, Filter, Globe, Network } from 'lucide-react';
import { DateTime } from 'luxon';
import { useSearchParams } from 'react-router-dom';

const Assets = () => {
  const [searchParams] = useSearchParams();
  const {
    mappedAsns,
    mappedSelectedAsns,
    page,
    handlePageChange,
    assets,
    isPending,
    error,
    totalPages,
    totalCount,
    prefetchChangePage,
    setDomainname,
    setDomainnameFilterOperator,
    setAsn,
    setTechnology,
    setTechnologyFilterOperator,
    setCreatedAt,
    setCreatedAtFilterOperator,
    setDomainSource,
    setDomainSourceFilterOperator,
    setLastScanned,
    setLastScannedFilterOperator,
    setIpaddress,
    setIpaddressFilterOperator,
    setTechnologyChangeWindow,
    setTechnologyChangeWindowFilterOperator,
    domainname,
    domainnameFilterOperator,
    technology,
    technologyFilterOperator,
    createdAt,
    createdAtFilterOperator,
    lastScanned,
    lastScannedFilterOperator,
    domainSource,
    domainSourceFilterOperator,
    technologyChangeWindow,
    technologyChangeWindowFilterOperator,
    ipaddress,
    ipaddressFilterOperator,
    technologiesSuggestions,
    sortField,
    setSortField,
    sortDirection,
    setSortDirection,
  } = useAssetQueries();
  const prefetchAssetHistory = usePrefetchAssetHistoryQuery();
  const prefetchAssetDetails = usePrefetchAssetDetails();

  const handlePrefetchHistory = (asset: AssetWithDetails) => {
    prefetchAssetHistory({ assetId: asset.id });
    prefetchAssetDetails({ assetId: asset.id });
  };

  const TechnologiesList = ({ technologies }: { technologies: string[] }) => {
    const filteredTech = technologies.map((tech) => {
      if (tech.startsWith('Font Awesome:')) {
        return 'Font Awesome';
      }
      return tech;
    });

    const displayTech = filteredTech.slice(0, 3).join(', ');
    const remainingCount = filteredTech.length - 3;

    return (
      <div className="flex flex-col flex-wrap justify-start items-start">
        {displayTech || '-'}
        {remainingCount > 0 && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button className="text-slate-500" variant="link" size="sm">
                  +{remainingCount} more
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <div>
                  <h3 className="font-bold mb-2">All Technologies</h3>
                  <ul className="list-disc pl-4">
                    {filteredTech.map((tech, index) => (
                      <li className="text-center" key={index}>
                        {tech}
                      </li>
                    ))}
                  </ul>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    );
  };

  const handleAnalyzeXHR = (asset: AssetWithDetails) => {
    const url = `/http_analyzer?url=http${asset.port === 443 ? 's' : ''}://${asset.hostname}`;
    window.open(url, '_blank');
  };

  const handleNavigateToAsset = () => {
    const params = new URLSearchParams(searchParams);
    params.delete('technology');
  };

  const headerWithSorting = (header: string, key: string) => {
    const isSelected = sortField === key;
    return (
      <div className="flex items-center gap-2">
        <span className={isSelected ? 'font-bold' : ''}>{header}</span>
        {isSelected && sortDirection === 'asc' ? (
          <ArrowUp
            className="h-4 w-4 cursor-pointer text-primary"
            onClick={() => {
              setSortField(key);
              setSortDirection('desc');
            }}
            data-click-bypass="true"
          />
        ) : isSelected && sortDirection === 'desc' ? (
          <ArrowDown
            className="h-4 w-4 cursor-pointer text-primary"
            onClick={() => {
              setSortField('');
              setSortDirection('');
            }}
            data-click-bypass="true"
          />
        ) : (
          <ArrowUpDown
            className="h-4 w-4 cursor-pointer"
            onClick={() => {
              setSortField(key);
              setSortDirection('asc');
            }}
            data-click-bypass="true"
          />
        )}
      </div>
    );
  };

  const columnHelper = createColumnHelper<AssetWithDetails>();

  const columns: ColumnDef<AssetWithDetails>[] = [
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <AIActionsMenu>
          {[
            <span
              key="analyze-xhr"
              className="flex justify-center items-center cursor-pointer"
              data-click-bypass="true"
              onClick={() => handleAnalyzeXHR(row.original)}
              title="Analyze XHR"
            >
              Analyze XHR
            </span>,
          ]}
        </AIActionsMenu>
      ),
    },
    columnHelper.accessor('hostname', {
      header: () => headerWithSorting('Hostname', 'hostname'),
      cell: ({ row }) => (
        <LinkWithAction
          onClick={() => handleNavigateToAsset()}
          showAsButton={true}
          buttonIcon={<Globe className="h-4 w-4" />}
          to={`/assets/${row.original.id}?from=assets`}
          title="View asset details"
          dataClickBypass={true}
          actions={[
            {
              label: 'Set Domain Name Filter',
              onClick: () => setDomainname(row.original.hostname),
              icon: <Filter />,
            },
          ]}
        >
          {row.original.hostname}
        </LinkWithAction>
      ),
    }),
    columnHelper.accessor('port', {
      header: 'Port',
      cell: ({ row }) => row.original.port,
    }),
    columnHelper.accessor('ipAddress', {
      header: 'IP Address',
      cell: ({ row }) => {
        const ipAddresses = row.original.dns_record.ipaddress;
        if (ipAddresses.length === 1) {
          return (
            <LinkWithAction
              showAsButton={true}
              buttonIcon={<Network className="h-4 w-4" />}
              to={`/ipaddress/${ipAddresses[0].ipaddress}?from=assets`}
              title="View IP Address details"
              dataClickBypass={true}
              actions={[
                {
                  label: 'Set IP Address Filter',
                  onClick: () => setIpaddress(ipAddresses[0].ipaddress),
                  icon: <Filter />,
                },
              ]}
            >
              {ipAddresses[0].ipaddress}
            </LinkWithAction>
          );
        }
        return (
          <div className="flex flex-col gap-2">
            {ipAddresses.map((ip) => (
              <LinkWithAction
                showAsButton={true}
                buttonIcon={<Network className="h-4 w-4" />}
                key={ip.ipaddress}
                to={`/ipaddress/${ip.ipaddress}?from=assets`}
                dataClickBypass={true}
                title="View IP Address details"
                actions={[
                  {
                    label: 'Set IP Address Filter',
                    onClick: () => setIpaddress(ip.ipaddress),
                    icon: <Filter />,
                  },
                ]}
              >
                {ip.ipaddress}
              </LinkWithAction>
            ))}
          </div>
        );
      },
    }),
    {
      header: 'Owner',
      accessorFn: (row) => (row.asnDetails ? row.asnDetails.owner : 'N/A'),
      cell: ({ row }) => (
        <ElementWithActions
          dataClickBypass={true}
          actions={[
            {
              label: 'Set ASN Filter',
              onClick: () =>
                setAsn([
                  row.original.asnDetails.asn !== 'N/A' ? row.original.asnDetails.asn : 'Unknown',
                ]),
              icon: <Filter />,
            },
          ]}
        >
          <div className="w-fit">
            {row.original.asnDetails ? row.original.asnDetails.owner : 'N/A'}
          </div>
        </ElementWithActions>
      ),
    },
    {
      header: 'Technology',
      cell: ({ row }) => <TechnologiesList technologies={row.original.technologies} />,
    },
    columnHelper.accessor('total_urls', {
      header: 'URLs',
      cell: ({ row }) => row.original.total_urls,
    }),
    columnHelper.accessor('vulnerabilities_count', {
      header: 'Vulnerabilities',
      cell: ({ row }) =>
        row.original.vulnerabilities_count + row.original.nuclei_vulnerabilities_count,
    }),
    columnHelper.accessor('last_scanned', {
      header: () => headerWithSorting('Last Scanned', 'last_scanned'),
      cell: ({ row }) =>
        DateTime.fromISO(row.original.last_scanned).toLocaleString({
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
    }),
    columnHelper.accessor('created_at', {
      header: () => headerWithSorting('Created At', 'created_at'),
      cell: ({ row }) =>
        DateTime.fromISO(row.original.created_at).toLocaleString({
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
    }),
  ];

  const availableFilters: AvailableFilter[] = [
    {
      type: 'multiSelect',
      key: 'asn',
      label: 'ASN',
      placeholder: 'Select ASNs',
      options: mappedAsns,
      state: mappedSelectedAsns,
      setState: setAsn,
    },
    {
      type: 'domainSearch',
      key: 'domainname',
      label: 'Domain Name',
      placeholder: 'Enter Domain Name',
      state: domainname,
      setState: setDomainname,
      valueType: domainnameFilterOperator,
      setValueType: setDomainnameFilterOperator,
      options: [
        {
          label: 'Starts with',
          value: 'startswith',
        },
        {
          label: 'Contains',
          value: 'contains',
        },
      ],
    },
    {
      type: 'text-with-operator',
      key: 'ipaddress',
      label: 'IP Address',
      placeholder: 'Enter IP Address',
      state: ipaddress,
      setState: setIpaddress,
      valueType: ipaddressFilterOperator,
      setValueType: setIpaddressFilterOperator,
      options: [
        {
          label: 'Include',
          value: 'include',
        },
        {
          label: 'Exclude',
          value: 'exclude',
        },
      ],
      onClear: () => {
        setIpaddress('');
        setIpaddressFilterOperator(null);
      },
    },
    {
      type: 'select-with-operator',
      key: 'technology',
      label: 'Technology',
      placeholder: 'Select Technology',
      state: technology,
      setState: setTechnology,
      valueType: technologyFilterOperator,
      setValueType: setTechnologyFilterOperator,
      options: technologiesSuggestions.map((tech) => ({ label: tech, value: tech })),
      operatorOptions: [
        {
          label: 'Include',
          value: 'include',
        },
        {
          label: 'Exclude',
          value: 'exclude',
        },
      ],
      onClear: () => {
        setTechnology('');
        setTechnologyFilterOperator(null);
      },
    },
    {
      type: 'text-with-operator',
      key: 'technologyChangeWindow',
      label: 'Technology Change Window',
      placeholder: '1d or 1h',
      state: technologyChangeWindow,
      setState: setTechnologyChangeWindow,
      valueType: technologyChangeWindowFilterOperator,
      setValueType: setTechnologyChangeWindowFilterOperator,
      options: [
        {
          label: 'Newer Than',
          value: 'greater_than',
        },
        {
          label: 'Older Than',
          value: 'less_than',
        },
      ],
      onClear: () => {
        setTechnologyChangeWindow('');
        setTechnologyChangeWindowFilterOperator(null);
      },
    },
    {
      type: 'text-with-operator',
      key: 'createdAt',
      label: 'Created At',
      placeholder: '1d or 1h',
      state: createdAt,
      setState: setCreatedAt,
      valueType: createdAtFilterOperator,
      setValueType: setCreatedAtFilterOperator,
      options: [
        {
          label: 'Newer Than',
          value: 'greater_than',
        },
        {
          label: 'Older Than',
          value: 'less_than',
        },
      ],
      operatorOptions: [
        {
          label: 'Newer Than',
          value: 'greater_than',
        },
        {
          label: 'Older Than',
          value: 'less_than',
        },
      ],
      onClear: () => {
        setCreatedAt('');
        setCreatedAtFilterOperator(null);
      },
    },
    {
      type: 'select-with-operator',
      key: 'domainSource',
      label: 'Domain Source',
      placeholder: 'Select Domain Source',
      state: domainSource,
      setState: setDomainSource,
      valueType: domainSourceFilterOperator,
      setValueType: setDomainSourceFilterOperator,
      options: technologiesSuggestions.map((tech) => ({ label: tech, value: tech })),
      operatorOptions: [
        {
          label: 'Include',
          value: 'include',
        },
        {
          label: 'Exclude',
          value: 'exclude',
        },
      ],
      onClear: () => {
        setDomainSource('');
        setDomainSourceFilterOperator(null);
      },
    },
    {
      type: 'text-with-operator',
      key: 'lastScanned',
      label: 'Last Scanned',
      placeholder: '1d or 1h',
      state: lastScanned,
      setState: setLastScanned,
      valueType: lastScannedFilterOperator,
      setValueType: setLastScannedFilterOperator,
      options: [
        {
          label: 'Newer Than',
          value: 'greater_than',
        },
        {
          label: 'Older Than',
          value: 'less_than',
        },
      ],
      onClear: () => {
        setLastScanned('');
        setLastScannedFilterOperator(null);
      },
    },
  ];

  return (
    <div className="p-4 max-w-[2000px] mx-auto">
      <UniversalFilter filters={availableFilters} clearAllFilters={() => {}} className="mb-4" />
      <DataTable
        columns={columns}
        data={assets}
        loading={isPending}
        currentPage={page}
        totalPages={totalPages}
        totalEntries={totalCount}
        onPageChange={handlePageChange}
        error={error}
        tableHeight="calc(100vh - 250px)"
        onRowHover={(row) => handlePrefetchHistory(row)}
        prefetchChangePage={prefetchChangePage}
      />
    </div>
  );
};

export default Assets;
