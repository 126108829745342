import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import AssetSources from '@/GridComponents/AssetSources';
import { useSyncedParams } from '@/hooks/useSynchedState';
import NotesTabDisplay from '@/Notes/NotesTabDisplay';
import { AssetDetailsWithDetails } from '@/types/api/response/assets';
import { ImageWithModal } from '@/v2/components/ImageWithModal/ImageWithModal';
import { AssetsCurrentStateIcon } from '@/v2/icons/Assets/AssetsSVG';
import { useGetAssetDetails, useGetAssetHistoryQuery } from '@/v2/queries/assets';
import URLDisplay from '@/v2/views/Assets/AssetURLView';
import {
  AlertCircle,
  ArrowLeft,
  ArrowRight,
  Calendar,
  Clock,
  Database,
  FileText,
  Globe,
  Hash,
  Network,
  Server,
  ShieldAlert,
  User,
} from 'lucide-react';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import HistoryView from './AssetHistoryView';
import AssetDetailVulnerabilities from './AssetVulnerabilitesView';

const tabs = [
  'current state',
  'vulnerabilities',
  'history',
  'urls',
  'Sourced From',
  'Notes',
] as const;

function AssetDetailSkeleton() {
  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="space-y-2">
            {[...Array(9)].map((_, i) => (
              <Skeleton key={i} className="h-6 w-full" />
            ))}
          </div>
        </div>
        <div>
          <Skeleton className="w-6/12 h-64 rounded-lg mx-auto" />
        </div>
      </div>
    </div>
  );
}

function AssetDetailCurrentState({ asset }: { asset: AssetDetailsWithDetails }) {
  return (
    <div className="grid grid-cols-2 gap-4 pb-8">
      <div className="space-y-6">
        <div className="mb-4 flex flex-col gap-2">
          <h2 className="text-2xl font-bold">Hostname: {asset.hostname}</h2>
          <p className="text-lg">
            <span className="font-semibold">Port:</span> {asset.port}
          </p>
        </div>

        <div className="space-y-2">
          <h3 className="text-lg font-semibold">DNS Information</h3>
          <p>
            <Globe className="inline mr-2" /> CNAME: {asset.cname ?? 'N/A'}
          </p>
          {asset.dns_record.ipaddress.length > 0 && (
            <>
              <p>
                <Hash className="inline mr-2" /> ASN #: {asset.asnDetails.asn}
              </p>
              <p>
                <Network className="inline mr-2" /> IP range: {asset.asnDetails.iprange}
              </p>
              <p>
                <User className="inline mr-2" /> Owner: {asset.asnDetails.owner}
              </p>
            </>
          )}
        </div>

        <div className="space-y-2">
          <h3 className="text-lg font-semibold">HTTP Information</h3>
          <p>
            <Server className="inline mr-2" /> Server Header:{' '}
            {asset.homepage_server_header ?? 'N/A'}
          </p>
          <p>
            <FileText className="inline mr-2" /> HTML Title: {asset.homepage_title ?? 'N/A'}
          </p>
          <p>
            <ArrowRight className="inline mr-2" /> Redirect URL:{' '}
            {asset.homepage_redirect_url ?? 'N/A'}
          </p>
          <p>
            <AlertCircle className="inline mr-2" /> HTTP Status Code:{' '}
            {asset.homepage_status_code ?? 'N/A'}
          </p>
        </div>

        <div className="space-y-2">
          <h3 className="text-lg font-semibold">Technologies</h3>
          <p>
            <Database className="inline mr-2" />
            {asset.technologies.length > 0
              ? asset.technologies.join(', \n')
              : 'No technologies identified'}
          </p>
        </div>

        <div className="space-y-2 text-sm text-gray-600">
          <p>
            <Clock className="inline mr-2" /> Last Scanned:{' '}
            {DateTime.fromISO(asset.last_scanned).toLocaleString()}
          </p>
          <p>
            <Calendar className="inline mr-2" /> Created At:{' '}
            {DateTime.fromISO(asset.created_at).toLocaleString()}
          </p>
        </div>
      </div>
      <div>
        <ImageWithModal imageUrl={asset.homepage_image} hostname={asset.hostname} />
      </div>
    </div>
  );
}

type AssetSearchParamsType = {
  tab: (typeof tabs)[number];
  from: string;
  cve?: string;
};

export default function AssetDetailView() {
  const [searchParams] = useSearchParams();
  const { synchedState, setSynchedState } = useSyncedParams<AssetSearchParamsType>({
    tab: 'current state',
    from: '',
    cve: '',
  });
  const { tab: selectedTab, from } = synchedState;
  const navigate = useNavigate();
  const { id } = useParams();
  const fromParam = searchParams.get('from');
  const backText = () => {
    if (!fromParam) return 'Back to List';
    if (fromParam.includes('ipaddress')) {
      return 'Back to IP Address';
    }
    if (fromParam.includes('investigation/passive')) {
      return 'Back to Investigation';
    }
    return 'Back to List';
  };

  const { data: asset, isFetching } = useGetAssetDetails(Number(id));

  const { data: historyData, isFetching: isHistoryFetching } = useGetAssetHistoryQuery({
    assetId: Number(id),
  });

  useEffect(() => {
    if (synchedState.cve) {
      setSynchedState({ tab: 'vulnerabilities' });
    }
  }, [synchedState.cve]);

  const handleBack = () => {
    searchParams.delete('from');
    searchParams.delete('tab');
    searchParams.delete('cve');
    searchParams.delete('urlPage');
    navigate(`/${from}?${searchParams.toString()}`);
  };

  const handleSelectedTab = (tab: (typeof tabs)[number]) => {
    setSynchedState({ tab, cve: null });
  };

  const renderTab = (tab: (typeof tabs)[number]) => {
    switch (tab) {
      case 'current state':
        return (
          <Button
            key={tab}
            className="p-0 h-auto rounded-none hover:bg-transparent"
            variant="ghost"
            onClick={() => handleSelectedTab(tab)}
          >
            <div
              className={`flex items-center gap-2 px-4 py-2 m-1 ${selectedTab === tab ? 'bg-white rounded-md text-black' : 'text-gray-500 hover:text-black'}`}
            >
              <AssetsCurrentStateIcon />
              <span>Current State</span>
            </div>
          </Button>
        );
      case 'vulnerabilities':
        return (
          <Button
            key={tab}
            className="p-0 h-auto rounded-none hover:bg-transparent"
            variant="ghost"
            onClick={() => handleSelectedTab(tab)}
          >
            <div
              className={`flex items-center gap-2 px-4 py-2 m-1 ${selectedTab === tab ? 'bg-white rounded-md text-black' : 'text-gray-500 hover:text-black'}`}
            >
              <ShieldAlert className="h-4 w-4" />
              <span>Vulnerabilities</span>
              <span className="bg-red-500 text-white text-xs px-1.5 py-0.5 rounded">
                {asset?.vulnerabilities.length + asset?.nuclei_vulnerabilities.length}
              </span>
            </div>
          </Button>
        );
      case 'history':
        return (
          <Button
            key={tab}
            className="p-0 h-auto rounded-none hover:bg-transparent"
            variant="ghost"
            onClick={() => handleSelectedTab(tab)}
          >
            <div
              className={`flex items-center gap-2 px-4 py-2 m-1 ${selectedTab === tab ? 'bg-white rounded-md text-black' : 'text-gray-500 hover:text-black'}`}
            >
              <Clock className="h-4 w-4" />
              <span>History</span>
              <span className="bg-red-500 text-white text-xs px-1.5 py-0.5 rounded">
                {asset?.history_count}
              </span>
            </div>
          </Button>
        );
      case 'urls':
        return (
          <Button
            key={tab}
            className="p-0 h-auto rounded-none hover:bg-transparent"
            variant="ghost"
            onClick={() => handleSelectedTab(tab)}
          >
            <div
              className={`flex items-center gap-2 px-4 py-2 m-1 ${selectedTab === tab ? 'bg-white rounded-md text-black' : 'text-gray-500 hover:text-black'}`}
            >
              <Globe className="h-4 w-4" />
              <span>URLs</span>
              <span className="bg-red-500 text-white text-xs px-1.5 py-0.5 rounded">
                {asset?.total_urls}
              </span>
            </div>
          </Button>
        );
      case 'Sourced From':
        return (
          <Button
            key={tab}
            className="p-0 h-auto rounded-none hover:bg-transparent"
            variant="ghost"
            onClick={() => handleSelectedTab(tab)}
          >
            <div
              className={`flex items-center gap-2 px-4 py-2 m-1 ${selectedTab === tab ? 'bg-white rounded-md text-black' : 'text-gray-500 hover:text-black'}`}
            >
              <Database className="h-4 w-4" />
              <span>Sourced From</span>
            </div>
          </Button>
        );
      case 'Notes':
        return (
          <Button
            key={tab}
            className="p-0 h-auto rounded-none hover:bg-transparent"
            variant="ghost"
            onClick={() => handleSelectedTab(tab)}
          >
            <div
              className={`flex items-center gap-2 px-4 py-2 mx-1 my-2 ${selectedTab === tab ? 'bg-white rounded-md text-black' : 'text-gray-500 hover:text-black'}`}
            >
              <FileText className="h-4 w-4" />
              <span>Notes</span>
              <span className="bg-red-500 text-white text-xs px-1.5 py-0.5 rounded">
                {asset?.total_notes}
              </span>
            </div>
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <div className="px-6">
      <div className="space-y-4 mx-auto justify-center">
        <div className="flex justify-between items-center">
          <Button onClick={handleBack} variant="ghost" size="sm" className="text-black w-auto">
            <ArrowLeft className="mr-2 h-4 w-4" />
            {backText()}
          </Button>
          <h1 className="text-xl font-semibold text-black">Asset Details</h1>
        </div>

        {asset && (
          <div className="flex mt-4 mb-8 bg-[#F9FAFA] rounded-lg w-fit ml-auto">
            {tabs.map((tab) => renderTab(tab))}
          </div>
        )}

        {isFetching ? (
          <AssetDetailSkeleton />
        ) : (
          asset && (
            <>
              {selectedTab === 'current state' && <AssetDetailCurrentState asset={asset} />}
              {selectedTab === 'vulnerabilities' && <AssetDetailVulnerabilities asset={asset} />}
              {selectedTab === 'history' && (
                <HistoryView
                  rowData={asset}
                  historyData={historyData.entries}
                  isFetching={isHistoryFetching}
                />
              )}
              {selectedTab === 'urls' && <URLDisplay httpAssetId={asset.id} />}
              {selectedTab === 'Sourced From' && <AssetSources entry={asset.domain} />}
              {selectedTab === 'Notes' && (
                <NotesTabDisplay assetid={asset.id} hostname={asset.hostname} />
              )}
            </>
          )
        )}
      </div>
    </div>
  );
}
